import React from 'react';
import {Breadcrumb, Flex, Radio} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {Link, Route, Routes, useNavigate, useParams} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {useWindowSize} from "@uidotdev/usehooks";
import {useSelector} from "react-redux";
import HomePage from "../Home/HomePage";
import ProfileSettings from "./pages/ProfileSettings";
import {RadioChangeEvent} from "antd";
import EmailSettings from "./pages/EmailSettings";

const SettingsPage = () => {
    const breadItems = [
        {
            title: <Link to={'/p/home'}><HomeOutlined /></Link>,
        },
        {
            title: <IntlMessage id={'settings'}/>,
        },
    ]

    const size = useWindowSize();
    const navigate = useNavigate();

    const {type} = useParams();

    const changeSettingsType = (e: RadioChangeEvent) => {
        navigate(`/p/settings/` + e.target.value);
    };

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <MyText uppercase strong size={"large"}>
                <IntlMessage id={'settings'}/>
            </MyText>
            <Breadcrumb
                items={breadItems}
            />
            <Radio.Group value={type} onChange={changeSettingsType}>
                <Radio.Button value={"profile"}><IntlMessage id={'profile'}/></Radio.Button>
                <Radio.Button value={"email"}><IntlMessage id={'email'}/></Radio.Button>
            </Radio.Group>
            {   type === 'profile' && <ProfileSettings/>    }
            {   type === 'email' && <EmailSettings/>    }
        </Flex>
    );
};

export default SettingsPage;