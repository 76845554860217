import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import {REACT_APP_NODE_ENV} from "./constants/env";
import './lang';
import {ScrollProvider} from "./components/Scroll/Scroll";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import SteveBloxButton from "./components/UI/Buttons/SteveBloxButton";
import BhargaButton from "./components/UI/Buttons/BhargaButton";
import MyButton from "./components/UI/Buttons/MyButton";
import HoverButton from "./components/UI/Buttons/HoverButton";
import LoginPage from "./pages/Login/LoginPage";
import FullLoading from "./components/LoadingComponents/FullLoading";
import {getUserByToken} from "./store/slices/userSlice";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Platform from "./pages/Platform/Platform";
import HomePage from "./pages/Home/HomePage";
import RegistrationPage from "./pages/Registration/RegistrationPage";
import NotFound from "./pages/404/NotFound";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import ChangePasswordPage from "./pages/ForgotPassword/ChangePasswordPage";
import ConfirmEmailPage from "./pages/ConfirmEmail/ConfirmEmailPage";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import ContactsPage from "./pages/Contacts/ContactsPage";
import LanguageButton from "./pages/components/Lan/LanguageButton";
import BackTop from "./pages/components/BackTop/BackTop";

if (REACT_APP_NODE_ENV === 'production') {
    disableReactDevTools();
}

function App() {
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserByToken());
    }, []);

    if (isLoading) {
        return <FullLoading/>;
    }

    return (
        <ScrollProvider>
            <BackTop/>
            <LanguageButton/>
            <Router>
                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/aboutUs' element={<AboutUsPage/>}/>
                    <Route path='/contacts' element={<ContactsPage/>}/>
                    <Route path='/login' element={user === null ? <LoginPage/> : <Navigate to="/p/home" replace />}/>
                    <Route path='/register' element={user === null ? <RegistrationPage/> : <Navigate to="/p/home" replace />}/>
                    <Route path='/forgot-password' element={user === null ? <ForgotPasswordPage/> : <Navigate to="/p/home" replace />}/>
                    <Route path='/change-password/:token' element={user === null ? <ChangePasswordPage/> : <Navigate to="/p/home" replace />}/>
                    <Route path='/confirm-email/:token' element={<ConfirmEmailPage/>}/>
                    {/*<Route path='/register' element={user === null ? <RegistrationPage/> : <Navigate to="/p/" replace />}/>*/}
                    <Route path='/p/*' element={user !== null ? <Platform/> : <Navigate to="/login" replace />}/>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </Router>
        </ScrollProvider>
    );
}

export default App;
