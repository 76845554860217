import React from 'react';
import {clrs} from "../../../../../constants/colors";
import {Alert, Flex, Form, Input, message} from "antd";
import cl from "../../../../global_styles.module.css";
import {useDispatch, useSelector} from "react-redux";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import {EmailValidator} from "../../../../../utils/EmailValidator";
import classes from "../../../../global_styles.module.css";
import {MailOutlined} from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";
import {useTranslation} from "react-i18next";
import SteveBloxButton from "../../../../../components/UI/Buttons/SteveBloxButton";
import UserService from "../../../../../services/UserService";
import {clearUser, setUserState} from "../../../../../store/slices/userSlice";

const EmailSettings = () => {

    const {t} = useTranslation();

    const {user} = useSelector(state => state.user);
    const [form] = Form.useForm();
    const email = Form.useWatch('email', form);
    const dispatch = useDispatch();

    function handleSave() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                await UserService.changeEmailOfUser(email)
                    .then(async (result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                    })
                    .catch((result) => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        if (result.response.status === 401) {
                            message.destroy();
                            localStorage.removeItem('Authorization');
                            dispatch(clearUser())
                            message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                        }
                    })
            })
            .catch(() => {
            })
    }

    async function handleResend() {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await UserService.resendEmailConfirmationMessage()
            .then(async (result) => {
                message.destroy();
                message.success(<IntlMessage id={'sent'}/>, 5);
            })
            .catch((result) => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    return (
        <Flex gap={20} vertical style={{width: "100%", padding: 15, backgroundColor: clrs.white, borderRadius: 8}}>
            {
                user?.personalData?.isEmailConfirmed === true &&
                <Alert
                    message={<IntlMessage id={'settings.emailConfirmed'}/>}
                    type={"info"}
                />
            }
            {
                user?.personalData?.isEmailConfirmed === false &&
                <Alert
                    message={<IntlMessage id={'settings.emailConfirmSend'}/>}
                    type={"warning"}
                />
            }
            <Form className={cl.form} form={form}
                  initialValues={{
                      email: user?.personalData?.email,
                  }}
            >
                <FormItem rules={[{
                    required: true,
                    message: <IntlMessage id={'requiredField'}/>,
                },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (EmailValidator.isValid(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('emailRules')));
                        },
                    }),
                ]} className={classes.formInputBlock} name="email">
                    <Input onInput={e => e.target.value = e.target.value.toLowerCase()} disabled={user?.personalData?.isEmailConfirmed} placeholder={t('email')} prefix={<MailOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                </FormItem>
                {
                    user?.personalData?.isEmailConfirmed !== true &&
                    <SteveBloxButton onClick={handleSave}><IntlMessage id={'save'}/></SteveBloxButton>
                }

            </Form>

            <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                {
                    user?.personalData?.isEmailConfirmed !== true &&
                    <SteveBloxButton onClick={handleResend}><IntlMessage id={'resend'}/></SteveBloxButton>
                }
            </Flex>
        </Flex>
    );
};

export default EmailSettings;