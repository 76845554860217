import React from 'react';
import classes from './dots.module.css';
const DotsLoader = () => {
    return (
        <section className={classes.dotsContainer}>
            <div className={classes.dot}></div>
            <div className={classes.dot}></div>
            <div className={classes.dot}></div>
            <div className={classes.dot}></div>
            <div className={classes.dot}></div>
        </section>
    );
};

export default DotsLoader;