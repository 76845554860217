import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Flex, Form, Input, message} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useWindowSize} from "@uidotdev/usehooks";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import UserService from "../../services/UserService";
import BrainBrewText from "../../components/BrainBrew/BrainBrewText";
import Block from "../components/Block/Block";
import {clrs} from "../../constants/colors";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import classes from "../global_styles.module.css";
import FormItem from "antd/lib/form/FormItem";
import {PasswordValidator} from "../../utils/PasswordValidator";
import {CheckCircleFilled, CheckCircleOutlined, CloseCircleFilled, KeyOutlined} from "@ant-design/icons";
import RedButton from "../../components/UI/Buttons/RedButton";

const ConfirmEmailPage = () => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const size = useWindowSize();

    const {token} = useParams();

    useEffect(() => {
        if (token) {
            message.loading(<IntlMessage id={'loading'}/>, 0);
            UserService.confirmEmail(token)
                .then(async (result) => {
                    message.destroy();
                    setLoading(false);
                    setSuccess(true);
                })
                .catch((result) => {
                    message.destroy();
                    if (result.response.data === 'email is confirmed') {
                        navigate('/login');
                    } else {
                        message.error(<IntlMessage id={'error'}/>, 5);
                        setError(true);
                    }

                })
        } else {
            message.error(<IntlMessage id={'error'}/>, 5);
            navigate('/login')
        }
    }, []);

    return (
        <Flex vertical gap={50} align={"center"} justify={"center"} style={{width: "100%", minHeight: "100vh", padding: size.width > 768 ? 100 : 20}}>
            <BrainBrewText/>
            <Block gap={25} align={"center"} justify={"center"} vertical style={{
                backgroundColor: clrs.yellow,
                position: "relative",
                maxWidth: 700,
                width: "100%",
                padding: size.width > 768 ? "25px 50px" : "25px 20px"
            }}>

                <BlockLoading isLoading={false}/>
                <p style={{color: clrs.white, fontWeight: "bold"}}><IntlMessage id={'confirmEmail'}/></p>
                {success &&
                    <>
                        <CheckCircleFilled style={{color: clrs.green, fontSize: "4rem"}}/>
                        <p style={{color: clrs.white, fontWeight: "bold"}}><IntlMessage id={'confirmedSuccess'}/></p>
                    </>
                }
                {error &&
                    <>
                        <CloseCircleFilled style={{color: clrs.green, fontSize: "4rem"}}/>
                        <p style={{color: clrs.white, fontWeight: "bold"}}><IntlMessage id={'confirmedFailed'}/></p>
                    </>
                }
            </Block>

        </Flex>
    );
};

export default ConfirmEmailPage;