import React, {useState} from 'react';
import {CommentOutlined, CustomerServiceOutlined} from "@ant-design/icons";
import {FloatButton} from "antd";
import classes from './lan.module.css';
import ru_flag from '../../../images/ru_flag_icon.png';
import kz_flag from '../../../images/kaz_glag_icon.png';
import i18n from "i18next";

const LanguageButton = () => {
    const [selectedLan, setSelectedLan] = useState({
        value: localStorage.getItem('lan'),
        label: localStorage.getItem('lan')
    });

    const [open, setOpen] = useState(false);

    const kz = {
        value: "ҚАЗ",
        label: "ҚАЗ",
        ii8n: "kk"
    }

    const ru = {
        value: "РУС",
        label: "РУС",
        ii8n: "ru"
    }

    const changeLang = (selectedLang) => {
        localStorage.setItem('lan', selectedLang.value);
        setSelectedLan(selectedLang);
        i18n.changeLanguage(selectedLang.ii8n);
        setOpen(false);
    }

    const selectedIconLan = selectedLan.value === 'РУС' ? <FlagIcon icon={ru_flag}/> : selectedLan.value === 'ҚАЗ' ? <FlagIcon icon={kz_flag}/> : <FlagIcon icon={ru_flag}/>

    return (
        <FloatButton.Group
            trigger="click"
            style={{
                right: 24,
                bottom: 48
            }}
            icon={selectedIconLan}
            open={open}
            onClick={() => {
                setOpen(!open);
            }}
        >
            {/*<FloatButton onClick={() => {changeLang(eng)}} icon={<FlagIcon icon={eng_flag}/>}/>*/}
            <FloatButton onClick={() => {changeLang(ru)}} icon={<FlagIcon icon={ru_flag}/>}/>
            <FloatButton onClick={() => {changeLang(kz)}} icon={<FlagIcon icon={kz_flag}/>}/>
        </FloatButton.Group>
    );
};

export default LanguageButton;

const FlagIcon = ({icon}) => {
    return (
        <div className={classes.iconFlagBlock}>
            <img src={icon} alt=""/>
        </div>
    );
};