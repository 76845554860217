export const subject_codes = {
    HISTORY_OF_KAZAKHSTAN: 'HISTORY_OF_KAZAKHSTAN',
    MATHEMATICAL_LITERACY: 'MATHEMATICAL_LITERACY',
    READING_LITERACY: 'READING_LITERACY',
    MATHEMATICS: 'MATHEMATICS',
    RUSSIAN_LANGUAGE: 'RUSSIAN_LANGUAGE',
    ENGLISH: 'ENGLISH',
    PHYSICS: 'PHYSICS',
    LITERATURE: 'LITERATURE',
    CHEMISTRY: 'CHEMISTRY',
    GERMAN: 'GERMAN',
    FRENCH: 'FRENCH',
    GEOGRAPHY: 'GEOGRAPHY',
    BASICS_OF_LAW: 'BASICS_OF_LAW',
    COMPUTER_SCIENCE: 'COMPUTER_SCIENCE',
    BIOLOGY: 'BIOLOGY',
    WORLD_HISTORY: 'WORLD_HISTORY'
};

export function getCombinations(subjectCode) {
    if (subjectCode === subject_codes.MATHEMATICS) {
        return [
            subject_codes.PHYSICS,
            subject_codes.COMPUTER_SCIENCE,
            subject_codes.GEOGRAPHY,
        ];
    }
    if (subjectCode === subject_codes.PHYSICS) {
        return [
            subject_codes.MATHEMATICS,
            subject_codes.CHEMISTRY,
        ];
    }
    if (subjectCode === subject_codes.COMPUTER_SCIENCE) {
        return [
            subject_codes.MATHEMATICS,
        ];
    }
    if (subjectCode === subject_codes.GEOGRAPHY) {
        return [
            subject_codes.MATHEMATICS,
            subject_codes.BIOLOGY,
            subject_codes.ENGLISH,
            subject_codes.WORLD_HISTORY,
        ];
    }
    if (subjectCode === subject_codes.BIOLOGY) {
        return [
            subject_codes.GEOGRAPHY,
            subject_codes.CHEMISTRY,
        ];
    }
    if (subjectCode === subject_codes.CHEMISTRY) {
        return [
            subject_codes.BIOLOGY,
            subject_codes.PHYSICS,
        ];
    }
    if (subjectCode === subject_codes.ENGLISH) {
        return [
            subject_codes.GEOGRAPHY,
            subject_codes.WORLD_HISTORY,
        ];
    }
    if (subjectCode === subject_codes.WORLD_HISTORY) {
        return [
            subject_codes.ENGLISH,
            subject_codes.GEOGRAPHY,
            subject_codes.BASICS_OF_LAW,
        ];
    }
    if (subjectCode === subject_codes.BASICS_OF_LAW) {
        return [
            subject_codes.WORLD_HISTORY,
        ];
    }
    if (subjectCode === subject_codes.RUSSIAN_LANGUAGE) {
        return [
            subject_codes.LITERATURE,
        ];
    }
    if (subjectCode === subject_codes.LITERATURE) {
        return [
            subject_codes.RUSSIAN_LANGUAGE,
        ];
    }
    return [];
}
