import React from 'react';
import {clrs} from "../../../constants/colors";
import footerBack from "../../../images/footerBack.png";
import IntlMessage from "../../../components/IntlMessage/IntlMessage";
import Block from "../Block/Block";
import {useWindowSize} from "@uidotdev/usehooks";
import {Link} from "react-router-dom";
import {Flex} from "antd";

const Footer = () => {
    const size = useWindowSize();
    return (
        <Block gap={25} justify={"flex-start"} align={"flex-start"}
               style={{
                   backgroundColor: clrs.blacker2,
                   padding: "25px 50px",
                   position: "relative",
                   color: clrs.white
               }} vertical>
            <Flex gap={25} vertical justify={"flex-start"} align={"flex-start"} style={{width: "100%", zIndex: 100}}>
                <Link to={'/contacts'}>
                    <p style={{
                        fontSize: size.width > 425 ? "1.2rem" : "1rem",
                        zIndex: 100,
                        color: clrs.white,
                        fontWeight: "bold",
                    }}>
                        <IntlMessage id={'footer.contacts'}/>
                    </p>
                </Link>
                <Link to={''}>
                    <p style={{
                        fontSize: size.width > 425 ? "1.2rem" : "1rem",
                        zIndex: 100,
                        color: clrs.white,
                        fontWeight: "bold",
                    }}>
                        <IntlMessage id={'footer.faq'}/>
                    </p>
                </Link>
                <Link to={''}>
                    <p style={{
                        fontSize: size.width > 425 ? "1.2rem" : "1rem",
                        zIndex: 100,
                        color: clrs.white,
                        fontWeight: "bold",
                    }}>
                        <IntlMessage id={'footer.ruleUse'}/>
                    </p>
                </Link>
                <Link to={''}>
                    <p style={{
                        fontSize: size.width > 425 ? "1.2rem" : "1rem",
                        zIndex: 100,
                        color: clrs.white,
                        fontWeight: "bold",
                    }}>
                        <IntlMessage id={'footer.confidentialPolitics'}/>
                    </p>
                </Link>
            </Flex>

            <img style={{width: "500px", zIndex: 0, top: 25, right: 50, position: "absolute"}} src={footerBack} alt=""/>

        </Block>
    );
};

export default Footer;