import React, {useEffect, useRef, useState} from 'react';
import {Flex, message, Progress, Statistic} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {changeTimeStatus} from "../../../../../../store/slices/platform/timeStatusSlice";
import {clrs} from "../../../../../../constants/colors";
import MyText from "../../../../../../components/UI/Text/MyText";
import IntlMessage from "../../../../../../components/IntlMessage/IntlMessage";
import QuizService from "../../../../../../services/QuizService";
import {clearUser} from "../../../../../../store/slices/userSlice";

const TimeStatus = ({deadline, time, quiz, setQuiz}) => {

    const dispatch = useDispatch();
    const progress = useSelector(state => state.timeStatus.progress);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Date.now();
            const timeRemaining = Math.max(0, deadline.getTime() - now);
            const progressPercentage = ((100 * timeRemaining) / (time)) | 0; // Calculate progress percentage

            dispatch(changeTimeStatus(progressPercentage))

            if (timeRemaining === 0) {
                clearInterval(interval);
                // You can add additional logic when the countdown reaches zero
                message.loading(<IntlMessage id={'quiz.finishingTest'}/>, 0);
                QuizService.finishQuiz(quiz.id)
                    .then((result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        setQuiz(result.data);
                    })
                    .catch((result) => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        if (result.response.status === 401) {
                            message.destroy();
                            localStorage.removeItem('Authorization');
                            dispatch(clearUser())
                            message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                        }
                    })
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [deadline]);

    return (
        <Flex vertical align={"center"} justify={"center"} gap={30} style={{padding: 20, backgroundColor: "white", height: 350, borderRadius: 8}}>
            <MyText style={{fontWeight: 400}}><IntlMessage id={'quiz.timeLeft'}/></MyText>
            <Progress size={250} strokeColor={clrs.blue} type="circle" percent={progress} format={() => (
                <Statistic.Countdown
                    value={deadline}
                    onFinish={() => {
                        // You can add additional logic when the countdown finishes
                    }}
                    valueStyle={{ fontSize: '2rem' }}
                />
            )}/>
        </Flex>
    );
};

export default TimeStatus;