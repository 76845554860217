import React, {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Checkbox, Flex, Image, message, Popconfirm, Radio, Select, Space, Steps} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import QuizService from "../../../../services/QuizService";
import {CheckCircleFilled, CloseCircleFilled, HomeOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {clrs} from "../../../../constants/colors";
import MatGramotnostIcon from "../../../../components/icons/subjectIcons/MatGramotnostIcon";
import GramotnostChteIcon from "../../../../components/icons/subjectIcons/GramotnostChteIcon";
import KazHistoryIcon from "../../../../components/icons/subjectIcons/KazHistoryIcon";
import classes from './quiz.module.css';
import {LocalName} from "../../../../utils/LocalName";
import HorizontalDivider from "../../../../components/Divider/HorizontalDivider";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {clearUser} from "../../../../store/slices/userSlice";
import {useDispatch} from "react-redux";
import SteveBloxButton from "../../../../components/UI/Buttons/SteveBloxButton";
import MyText from "../../../../components/UI/Text/MyText";
import parse from 'html-react-parser';
import {API_BASE_URL} from "../../../../constants/api";
import TimeStatus from "./components/TimeStatus/TimeStatus";
import Card from "../../../../components/LoadingComponents/Card";
import BhargaButton from "../../../../components/UI/Buttons/BhargaButton";
import QuizQuestionService from "../../../../services/QuizQuestionService";
import SubjectResultsChart from "./charts/SubjectResultsChart";
import SubjectItem from "./components/SubjectItem";
import {useWindowSize} from "@uidotdev/usehooks";
import TimeStatusMobile from "./components/TimeStatus/TimeStatusMobile";
import MyRadioButton from "./components/MyRadionButton/MyRadioButton";
import MyCheckboxButton from "./components/MyCheckboxButton/MyCheckboxButton";

const Step = Steps.Step;
const QuizPage = () => {

    const size = useWindowSize();

    const breadItems = [
        {
            title: <Link to={'/p/home'}><HomeOutlined /> <IntlMessage id={'personalCabinet'}/></Link>,
        },
        {
            title: <IntlMessage id={'quiz'}/>,
        },
    ]

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [quiz, setQuiz] = useState(null);

    const [time, setTime] = useState(null);
    const [deadline, setDeadline] = useState(null);

    const [current, setCurrent] = useState(0);

    const {quizId, subjectIndex, questionIndex, contextIndex} = useParams();

    const [loading, setLoading] = useState(true);

    const statNumbersBlock = useRef();
    const mainBlock = useRef();

    useEffect(() => {
        QuizService.getQuizById(quizId)
            .then((r) => {
                console.log(r.data);
                setQuiz(r.data);
                setTime(1000 * 60 * r.data?.minutes);
                setDeadline(new Date(r.data?.dueDate));
                if (!isValidParams(r.data)) {
                    if (r.data.subjects[0].questions.length > 0) {
                        navigate(`/p/quiz/${quizId}/0/question/0`);
                    } else {
                        navigate(`/p/quiz/${quizId}/0/context/0`);
                    }
                }

                setLoading(false);
            })
            .catch((result) => {
                console.log(result)
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }, []);

    function isNumeric(value) {
        return /^\d+$/.test(value);
    }

    function isValidParams(quiz) {
        if (!subjectIndex) {
            return false;
        }

        if (subjectIndex) {
            if (isNumeric(subjectIndex)) {
                if (quiz.subjects.length <= parseInt(subjectIndex)) {
                    return false;
                }
            } else {
                return false;
            }
        }

        if (questionIndex) {
            if (isNumeric(questionIndex)) {
                if (quiz.subjects[subjectIndex].questions.length <= parseInt(questionIndex)) {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            if (contextIndex) {
                if (isNumeric(contextIndex)) {
                    if (quiz.subjects[subjectIndex].contexts.length <= parseInt(contextIndex)) {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    }

    function onChangeSubject(value) {
        setCurrent(value);
        if (quiz.subjects[value].questions.length > 0) {
            navigate(`/p/quiz/${quizId}/${value}/question/0`);
        } else {
            navigate(`/p/quiz/${quizId}/${value}/context/0`);
        }
    }

    function getMaxQuestions() {
        let numOfQuestions = 0;
        numOfQuestions += quiz.subjects[subjectIndex]?.questions.length;
        quiz.subjects[subjectIndex]?.contexts.forEach(context => {
            numOfQuestions += context.numOfQuestions;
        })
        return numOfQuestions;
    }

    function getQuestion() {
        return quiz?.subjects[subjectIndex]?.questions[questionIndex];
    }

    function getContext() {
        return quiz?.subjects[subjectIndex]?.contexts[contextIndex];
    }

    const isPrevPage = () => {
        if (questionIndex) {
            if (parseInt(questionIndex) + 1 === 1) {
                return false;
            }
        }
        if (contextIndex) {
            let numOfDefaultQuestions = quiz?.subjects[subjectIndex].questions.length;
            if (numOfDefaultQuestions === 0 && parseInt(contextIndex) + 1 === 1) {
                return false;
            }
        }
        return true;
    }

    const isNextPage = () => {
        if (questionIndex) {
            if (parseInt(questionIndex) + 1 === getMaxQuestions()) {
                return false;
            }
        }
        if (contextIndex) {
            if (quiz?.subjects[subjectIndex].contexts.length === parseInt(contextIndex) + 1) {
                return false;
            }
        }
        return true;
    }

    function prevPage() {
        if (questionIndex) {
            navigate(`/p/quiz/${quizId}/${subjectIndex}/question/${parseInt(questionIndex) - 1}`);
        }
        if (contextIndex) {
            if (parseInt(contextIndex) - 1 >= 0) {
                navigate(`/p/quiz/${quizId}/${subjectIndex}/context/${parseInt(contextIndex) - 1}`);
            } else {
                let numOfDefaultQuestions = quiz?.subjects[subjectIndex].questions.length;
                navigate(`/p/quiz/${quizId}/${subjectIndex}/question/${numOfDefaultQuestions - 1}`);
            }
        }
    }

    function nextPage() {
        if (questionIndex) {
            let numOfDefaultQuestions = quiz?.subjects[subjectIndex].questions.length;
            console.log(parseInt(questionIndex) + 1 === numOfDefaultQuestions)
            if (parseInt(questionIndex) + 1 < numOfDefaultQuestions) {
                navigate(`/p/quiz/${quizId}/${subjectIndex}/question/${parseInt(questionIndex) + 1}`);
            } else {
                navigate(`/p/quiz/${quizId}/${subjectIndex}/context/0`);
            }
        }
        if (contextIndex) {
            if (parseInt(contextIndex) + 1 < quiz?.subjects[subjectIndex].contexts.length) {
                navigate(`/p/quiz/${quizId}/${subjectIndex}/context/${parseInt(contextIndex) + 1}`);
            }
        }
    }

    function getDefaultNumOfQuestions() {
        return quiz?.subjects[subjectIndex].questions.length;
    }

    function getContextQuestionNumbers() {
        let defaultNumOfQuestions = getDefaultNumOfQuestions();
        let firstNumber = defaultNumOfQuestions + 1;
        let lastNumber = -1;
        for (let i = 0; i < quiz?.subjects[subjectIndex]?.contexts.length; i++) {
            if (i === parseInt(contextIndex)) {
                lastNumber = firstNumber - 1 + quiz?.subjects[subjectIndex]?.contexts[i].numOfQuestions;
                return [firstNumber, lastNumber];
            } else {
                firstNumber += quiz?.subjects[subjectIndex]?.contexts[i].numOfQuestions;
            }
        }
        return [firstNumber, lastNumber];
    }

    function getQuestionList() {
        let questions = [];
        quiz?.subjects[subjectIndex]?.questions.forEach((q, i) => {
            let question = {
                label: i + 1,
                to: `/p/quiz/${quizId}/${subjectIndex}/question/${i}`,
                selected: questionIndex && parseInt(questionIndex) === i,
                isAnswerSelected: !!q.answers.find(a => a.isSelected),
                resultPoint: q.resultPoint,
                maxPoint: q.maxPointPublic
            }
            if (q.questionType === "DND") {
                let isFullySelected = !q.answers.find(ans => ans.selectedDndAnswer == null)
                question = {
                    ...question,
                    isAnswerSelected : isFullySelected
                }
            }
            questions.push(question);
        })

        let currentContextIndex = 0;
        let plusNum = 0;
        let numOfDefQuestions = getDefaultNumOfQuestions();

        quiz?.subjects[subjectIndex]?.contexts.forEach((c, i) => {
            c?.questions.forEach((q, i) => {
                let question = {
                    label: numOfDefQuestions + plusNum + i + 1,
                    to: `/p/quiz/${quizId}/${subjectIndex}/context/${currentContextIndex}`,
                    selected: contextIndex && parseInt(contextIndex) === currentContextIndex,
                    isAnswerSelected: !!q.answers.find(a => a.isSelected),
                    resultPoint: q.resultPoint,
                    maxPoint: q.maxPointPublic
                }
                questions.push(question);
            });
            currentContextIndex++;
            plusNum += c.numOfQuestions;
        })
        return questions;
    }

    async function handleToogleQuestion(e) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        const subjectId = quiz?.subjects[subjectIndex].id;
        const questionId = getQuestion()?.id;
        const answerId = e.target.value;
        const isSelected = e.target.checked;
        await QuizQuestionService.toogleQuestion(
            quizId,
            subjectId,
            questionId,
            answerId,
            isSelected)
            .then((result) => {
                setQuiz(result.data);
                message.destroy();
            })
            .catch((result) => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    async function handleSelectDnd(dndId, answerId) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        const subjectId = quiz?.subjects[subjectIndex].id;
        const questionId = getQuestion()?.id;
        await QuizQuestionService.selectDndAnswer(
            quizId,
            subjectId,
            questionId,
            answerId,
            dndId)
            .then((result) => {
                setQuiz(result.data);
                message.destroy();
            })
            .catch((result) => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    async function handleToogleQuestionContext(question, e) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        const subjectId = quiz?.subjects[subjectIndex].id;
        const contextId = getContext().id;
        const questionId = question.id;
        const answerId = e.target.value;
        const isSelected = e.target.checked;
        await QuizQuestionService.toogleQuestionOfContext(
            quizId,
            subjectId,
            contextId,
            questionId,
            answerId,
            isSelected)
            .then((result) => {
                setQuiz(result.data);
                message.destroy();
            })
            .catch((result) => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    async function handleFinishTest() {
        message.loading(<IntlMessage id={'quiz.finishingTest'}/>, 0);
        QuizService.finishQuiz(quiz.id)
            .then((result) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setQuiz(result.data);
                setCurrent(0);
                if (result.data.subjects[0].questions.length > 0) {
                    navigate(`/p/quiz/${quizId}/0/question/0`);
                } else {
                    navigate(`/p/quiz/${quizId}/0/context/0`);
                }
            })
            .catch((result) => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    function calculateTimeDifference(createdAt, dueDate, finishedAt) {
        // Преобразование строк в объекты Date
        const created = new Date(createdAt);
        const due = new Date(dueDate);
        const finished = new Date(finishedAt);

        let difference;

        // Проверка условия, если finishedAt после dueDate
        if (finished > due) {
            // Разница между createdAt и dueDate
            difference = due - created;
        } else {
            // Разница между createdAt и finishedAt
            difference = finished - created;
        }

        // Преобразование разницы в минуты и возврат результата
        return parseInt(Math.abs(difference / 60000));
    }

    // useEffect(() => {
    //     console.log(mainBlock.current)
    // }, [mainBlock.current]);

    if (loading) {
        return (
            <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
                <Card type={'horizontal-small'}/>
                <HorizontalDivider/>
                <Flex style={{width: "100%", position: "relative"}} align={"center"} justify={"center"} gap={30}>
                    <Flex style={{width: "70%", position: "relative"}} align={"flex-start"} justify={"flex-start"}
                          vertical gap={15}>
                        <Card type={'horizontal-big'}/>
                    </Flex>
                    <Flex style={{width: "30%", position: "relative"}} vertical>
                        <Card type={'horizontal-circle'}/>
                        <Card type={'horizontal'}/>
                    </Flex>
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex style={{width: "100%", overflow: "scroll", paddingBottom: 100}} align={"flex-start"} justify={"flex-start"} gap={20} ref={mainBlock} vertical>
            <Breadcrumb
                items={breadItems}
            />
            {
                quiz?.isFinished &&
                <>
                    <Flex style={{width: "100%", position: "relative"}} align={"center"} gap={20}
                          justify={"center"} wrap={"wrap"} >
                        <Flex ref={statNumbersBlock} style={{width: "100%", maxWidth: 600, position: "relative"}} align={"center"} gap={20}
                              justify={"space-between"} wrap={statNumbersBlock.current?.offsetWidth < 500 ? "wrap" : "nowrap" }>
                            <Flex style={{
                                width: "100%",
                                maxWidth: statNumbersBlock.current?.offsetWidth < 500 ? "100%" : 300,
                                minWidth: 200,
                                height: 250,
                                position: "relative",
                                borderRadius: 8,
                                padding: 20,
                                backgroundColor: clrs.white
                            }} gap={20} align={"center"} justify={"center"} vertical>
                                <MyText strong style={{fontSize: "3rem", color: clrs.blacker, textAlign: "center"}}>
                                    {quiz.resultPoint} / {quiz.maxPoint}
                                </MyText>
                                <MyText style={{color: clrs.whiterBlacker, textAlign: "center"}}>
                                    <IntlMessage id={'quiz.yourResult'}/>
                                </MyText>
                            </Flex>
                            <Flex style={{
                                width: "100%",
                                height: 250,
                                maxWidth: statNumbersBlock.current?.offsetWidth < 500 ? "100%" : 250,
                                minWidth: 200,
                                position: "relative",
                                borderRadius: 8,
                                padding: 20,
                                backgroundColor: clrs.white
                            }} gap={20} align={"center"} justify={"center"} vertical>
                                <MyText strong style={{fontSize: "3rem", color: clrs.blacker, textAlign: "center"}}>
                                    {calculateTimeDifference(quiz.createdAt, quiz.dueDate, quiz.finishedAt)} минут
                                </MyText>
                                <MyText style={{color: clrs.whiterBlacker, textAlign: "center"}}>
                                    <IntlMessage id={'quiz.totalTime'}/>
                                </MyText>
                            </Flex>
                        </Flex>

                        <SubjectResultsChart quiz={quiz}/>
                    </Flex>
                    <HorizontalDivider/>
                </>
            }
            <Flex style={{width: "100%", overflow: "scroll", paddingBottom: 20}}>
                {/*<Steps*/}
                {/*    direction={"horizontal"}*/}
                {/*    style={{minWidth: 650, width: "100%", justifyContent: "space-around", gap: 25}}*/}
                {/*    responsive={false}*/}
                {/*    current={current}*/}
                {/*    // progressDot*/}
                {/*    onChange={(value: number) => {*/}
                {/*        onChangeSubject(value)*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {*/}
                {/*        quiz?.subjects.map((subject, i) => {*/}
                {/*            return (*/}
                {/*                <Step key={subject.id}*/}
                {/*                      className={[classes.stepContainer, current === i ? classes.currentStep : ""].join(" ")}*/}
                {/*                      status={current === i ? "process" : "wait"}*/}
                {/*                      title={LocalName.getName(subject)} icon={<SubjectIcon subject={subject}/>}/>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*</Steps>*/}
                <Flex style={{width: "100%", minWidth: 650}} gap={50} align={"center"} justify={"space-around"}>
                    {
                        quiz?.subjects.map((subject, i) => {
                            return (
                                <SubjectItem onClick={() => {onChangeSubject(i)}} subject={subject} key={subject.id}
                                             className={[classes.stepContainer, current === i ? classes.currentStep : ""].join(" ")}/>
                            )
                        })
                    }
                </Flex>
            </Flex>
            <HorizontalDivider/>

            <Flex style={{width: "100%", position: "relative"}} justify={"space-between"} gap={30}>
                <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"flex-start"} vertical
                      gap={15}>
                    {
                        size.width <= 1025 && !quiz.isFinished &&
                        <Flex vertical align={"center"} justify={"center"} gap={20}
                              style={{backgroundColor: clrs.white, padding: 15, width: "100%", borderRadius: 8}}>
                            <TimeStatusMobile deadline={deadline} quiz={quiz} setQuiz={setQuiz}/>
                        </Flex>
                    }
                    {
                        size.width <= 1024 &&
                        <Flex style={{width: "100%", position: "relative"}}>
                            <Flex vertical align={"center"} justify={"center"} gap={20}
                                  style={{backgroundColor: clrs.white, padding: 15, width: "100%", borderRadius: 8}}>
                                <MyText style={{fontWeight: 400}}><IntlMessage id={'questions'}/></MyText>
                                {
                                    quiz.isFinished ?
                                        <Flex gap={10} wrap={"wrap"}>
                                            {getQuestionList().map((question, i) => {
                                                return (
                                                    <Link key={new Date() + i} to={question?.to}
                                                          className={[question?.selected && classes.questionsListItemCurrent2, classes.questionsListItem2, question.maxPoint - question.resultPoint === 0 ? classes.questionsListItemCorrect : question.resultPoint === 0 ? classes.questionsListItemInCorrect : classes.questionsListItemPartlyCorrect].join(" ")}>{question?.label}</Link>
                                                )
                                            })}

                                        </Flex>
                                        :
                                        <Flex gap={10} wrap={"wrap"}>
                                            {getQuestionList().map((question, i) => {
                                                return (
                                                    <Link key={new Date() + i} to={question?.to}
                                                          className={[classes.questionsListItem, question?.selected && classes.questionsListItemCurrent, question?.isAnswerSelected && classes.questionsListItemIsAnswerSelected].join(" ")}>{question?.label}</Link>
                                                )
                                            })}

                                        </Flex>
                                }
                            </Flex>
                        </Flex>
                    }



                    <Flex style={{width: "100%", position: "relative"}} align={"center"} justify={"space-between"}
                          gap={20}>
                        <SteveBloxButton onClick={prevPage} disabled={!isPrevPage()}>
                            <LeftOutlined/>
                            {
                                size.width > 1024 &&
                                <span><IntlMessage id={'quiz.prev'}/></span>
                            }

                        </SteveBloxButton>
                        <Flex style={{padding: "10px 25px", textAlign: "center", backgroundColor: clrs.white, borderRadius: "10px"}}>
                            {
                                questionIndex &&
                                <span>Вопрос №{parseInt(questionIndex) + 1} из {getMaxQuestions()}</span>
                            }
                            {
                                contextIndex &&
                                <span>Вопросы №{getContextQuestionNumbers()[0]}-{getContextQuestionNumbers()[1]} из {getMaxQuestions()}</span>
                            }

                        </Flex>
                        <SteveBloxButton onClick={nextPage} disabled={!isNextPage()}>
                            {
                                size.width > 1024 &&
                                <span><IntlMessage id={'quiz.next'}/></span>
                            }
                            <RightOutlined/>
                        </SteveBloxButton>
                    </Flex>
                    {
                        questionIndex && (
                            <Flex style={{width: "100%", position: "relative"}}>
                                <Flex key={getQuestion().id} align={"flex-start"} justify={"flex-start"} vertical
                                      style={{
                                          padding: 20,
                                          width: "100%",
                                          minHeight: "150px",
                                          backgroundColor: "white",
                                          borderRadius: 8,
                                          position: "relative"
                                      }} gap={20}>
                                    {getQuestion().questionText && getQuestion().questionText !== 'null' &&
                                        <MyText>{parse(getQuestion().questionText)}</MyText>}
                                    <Flex wrap={"wrap"} align={"flex-start"} justify={"flex-start"} gap={20}>
                                        {
                                            getQuestion().images.map((questionImage, i) => {
                                                return (
                                                    <Image key={questionImage.id} style={{maxHeight: 400, maxWidth: 400}}
                                                           src={API_BASE_URL + "/api/file/get/" + questionImage.fileName}/>
                                                )
                                            })
                                        }
                                    </Flex>
                                    {
                                        getQuestion().questionType === 'SINGLE' &&
                                        <Radio.Group disabled={quiz.isFinished}
                                                     defaultValue={getQuestion().answers.find(answer => answer.isSelected)?.id}
                                                     onChange={(e) => {
                                                         handleToogleQuestion(e)
                                                     }}
                                                     style={{width: "100%"}}
                                        >
                                            <Space direction="vertical" style={{width: "100%"}}>
                                                {
                                                    getQuestion().answers.map((answer, i) => {
                                                        return (
                                                            <MyRadioButton quiz={quiz} answer={answer} key={answer.id}/>
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Radio.Group>
                                    }
                                    {
                                        getQuestion().questionType === 'MULTIPLE' &&
                                        <Space direction="vertical" style={{width: "100%"}}>
                                            {
                                                getQuestion().answers.map((answer, i) => {
                                                    return (
                                                        <MyCheckboxButton quiz={quiz} answer={answer} handleToogleQuestion={handleToogleQuestion} getQuestion={getQuestion}/>
                                                    )
                                                })
                                            }
                                        </Space>
                                    }
                                    {
                                        getQuestion().questionType === 'DND' &&
                                        <Flex style={{ width: "100%" }} vertical align={"flex-start"} justify={"flex-start"} gap={15}>
                                            {
                                                getQuestion().answers.map((answer, i) => {
                                                    return (
                                                        <Flex key={answer.id} vertical gap={15} align={"flex-start"} justify={"flex-start"} style={{padding: 15, background: clrs.whiter, borderRadius: 8, width: "100%"}}>
                                                            <Flex align={"center"} justify={"center"} gap={10}>
                                                                <Flex align={"flex-start"} justify={"flex-start"}
                                                                      gap={10}
                                                                      vertical wrap={"wrap"}>
                                                                    {answer.answerText && answer.answerText !== 'null' &&
                                                                        <span>{parse(answer.answerText)}</span>}
                                                                    <Flex wrap={"wrap"} align={"flex-start"}
                                                                          justify={"flex-start"} gap={20}>
                                                                        {
                                                                            answer.images.map((answerImage, i) => {
                                                                                return (
                                                                                    <Image key={answerImage.id} style={{
                                                                                        maxHeight: 500,
                                                                                        maxWidth: 500
                                                                                    }}
                                                                                           src={API_BASE_URL + "/api/file/get/" + answerImage.fileName}/>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Flex>
                                                                </Flex>
                                                                {quiz.isFinished && (answer.isCorrect ?
                                                                    <CheckCircleFilled
                                                                        style={{color: clrs.green}}/> : answer.isSelected &&
                                                                    <CloseCircleFilled style={{color: clrs.red}}/>)}
                                                            </Flex>
                                                            <Flex style={{ width: "100%" }}>
                                                                <Select
                                                                    disabled={quiz.isFinished}
                                                                    defaultValue={answer.selectedDndAnswer?.id}
                                                                    style={{ width: "100%" }}
                                                                    onChange={(e) => {
                                                                        handleSelectDnd(e, answer.id);
                                                                    }}
                                                                    options={getQuestion().dndAnswers.map(dndAns => (
                                                                        {
                                                                            value: dndAns.id,
                                                                            label: parse(dndAns.answer)
                                                                        }
                                                                    ))}
                                                                />
                                                            </Flex>
                                                            {
                                                                quiz.isFinished &&
                                                                <Flex >
                                                                    <p><strong style={{color: clrs.green}}><IntlMessage id={'quiz.correctAnswer'}/></strong>: <span>{parse(answer.correctDndAnswer?.answer)}</span></p>
                                                                </Flex>
                                                            }

                                                        </Flex>
                                                    )
                                                })
                                            }

                                        </Flex>
                                    }
                                </Flex>
                            </Flex>
                        )
                    }
                    {
                        contextIndex && (
                            <Flex style={{width: "100%", position: "relative"}} vertical>
                                <Flex align={"center"} justify={"center"} vertical wrap={"wrap"}
                                      style={{
                                          padding: 20,
                                          fontSize: "1rem",
                                          textAlign: "justify",
                                          width: "100%",
                                          minHeight: "150px",
                                          backgroundColor: "white",
                                          position: "relative",
                                          borderRadius: 8
                                      }} gap={20}>
                                    {getContext()?.text && getContext()?.text !== 'null' &&
                                        <span>{parse(getContext()?.text)}</span>}
                                    <Flex wrap={"wrap"} align={"flex-start"} justify={"flex-start"} gap={20}>
                                        {
                                            getContext()?.images.map((contextImage, i) => {
                                                return (
                                                    <Image key={contextImage.id} style={{maxHeight: 700, maxWidth: 700}}
                                                           src={API_BASE_URL + "/api/file/get/" + contextImage.fileName}/>
                                                )
                                            })
                                        }
                                    </Flex>
                                </Flex>

                                {
                                    getContext()?.questions.map((question, i) => {
                                        return (
                                            <Flex key={question.id} align={"flex-start"} justify={"flex-start"} vertical
                                                  style={{
                                                      padding: 20,
                                                      width: "100%",
                                                      minHeight: "150px",
                                                      backgroundColor: "white",
                                                      position: "relative"
                                                  }} gap={20}>
                                                <HorizontalDivider/>
                                                {question.questionText && question.questionText !== 'null' &&
                                                    <MyText>{parse(question.questionText)}</MyText>}
                                                <Flex wrap={"wrap"} align={"flex-start"} justify={"flex-start"} gap={20}>
                                                    {
                                                        question.images.map((questionImage, i) => {
                                                            return (
                                                                <Image key={questionImage.id}
                                                                       style={{maxHeight: 400, maxWidth: 400}}
                                                                       src={API_BASE_URL + "/api/file/get/" + questionImage.fileName}/>
                                                            )
                                                        })
                                                    }
                                                </Flex>
                                                {
                                                    question.questionType === 'SINGLE' &&
                                                    <Radio.Group disabled={quiz.isFinished}
                                                                 style={{width: "100%"}}
                                                                 defaultValue={getContext().questions.find((contextQuestion, i) => contextQuestion.id === question.id).answers.find(answer => answer.isSelected)?.id}
                                                                 onChange={(e) => {
                                                                     handleToogleQuestionContext(question, e)
                                                                 }}>
                                                        <Space direction="vertical" style={{width: "100%"}}>
                                                            {
                                                                question.answers.map((answer, i) => {
                                                                    return (
                                                                        <MyRadioButton quiz={quiz} answer={answer} key={answer.id}/>
                                                                    )
                                                                })
                                                            }
                                                        </Space>
                                                    </Radio.Group>
                                                }
                                            </Flex>
                                        )
                                    })
                                }
                            </Flex>
                        )
                    }

                    {
                        size.width <= 1025 && !quiz.isFinished &&
                        <Flex vertical align={"center"} justify={"center"} gap={20}
                              style={{padding: 15, width: "100%", borderRadius: 8}}>
                            {
                                !quiz.isFinished &&
                                <Popconfirm
                                    title={<IntlMessage id={'quiz.finishingTest2'}/>}
                                    description={<IntlMessage id={'quiz.finishingTest.youSure'}/>}
                                    onConfirm={handleFinishTest}
                                    okText={<IntlMessage id={'yes'}/>}
                                    cancelText={<IntlMessage id={'no'}/>}
                                >
                                    <BhargaButton style={{fontWeight: 500}}><IntlMessage
                                        id={'quiz.finishTest'}/></BhargaButton>
                                </Popconfirm>
                            }
                        </Flex>
                    }
                </Flex>
                {
                    size.width > 1024 &&
                    <Flex vertical gap={20} style={{position: "relative", maxWidth: 332}}>
                        {
                            !quiz.isFinished && <TimeStatus setQuiz={setQuiz} quiz={quiz} deadline={deadline} time={time}/>
                        }
                        <Flex vertical align={"center"} justify={"center"} gap={20}
                              style={{backgroundColor: clrs.white, padding: 15, borderRadius: 8}}>
                            <MyText style={{fontWeight: 400}}><IntlMessage id={'questions'}/></MyText>
                            {
                                quiz.isFinished ?
                                    <Flex gap={10} wrap={"wrap"}>
                                        {getQuestionList().map((question, i) => {
                                            return (
                                                <Link key={new Date() + i} to={question?.to}
                                                      className={[question?.selected && classes.questionsListItemCurrent2, classes.questionsListItem2, question.maxPoint - question.resultPoint === 0 ? classes.questionsListItemCorrect : question.resultPoint === 0 ? classes.questionsListItemInCorrect : classes.questionsListItemPartlyCorrect].join(" ")}>{question?.label}</Link>
                                            )
                                        })}

                                    </Flex>
                                    :
                                    <Flex gap={10} wrap={"wrap"}>
                                        {getQuestionList().map((question, i) => {
                                            return (
                                                <Link key={new Date() + i} to={question?.to}
                                                      className={[classes.questionsListItem, question?.selected && classes.questionsListItemCurrent, question?.isAnswerSelected && classes.questionsListItemIsAnswerSelected].join(" ")}>{question?.label}</Link>
                                            )
                                        })}

                                    </Flex>
                            }
                        </Flex>
                        {
                            !quiz.isFinished &&
                            <Popconfirm
                                title={<IntlMessage id={'quiz.finishingTest2'}/>}
                                description={<IntlMessage id={'quiz.finishingTest.youSure'}/>}
                                onConfirm={handleFinishTest}
                                okText={<IntlMessage id={'yes'}/>}
                                cancelText={<IntlMessage id={'no'}/>}
                            >
                                <SteveBloxButton style={{fontWeight: 500}}><IntlMessage
                                    id={'quiz.finishTest'}/></SteveBloxButton>
                            </Popconfirm>
                        }
                    </Flex>
                }
            </Flex>
        </Flex>

    );
};

export default QuizPage;

