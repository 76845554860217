import React, {useEffect, useState} from 'react';
import {Flex} from "antd";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {clrs} from "../../../../../constants/colors";
import MyText from "../../../../../components/UI/Text/MyText";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import {LocalName} from "../../../../../utils/LocalName";

const SubjectResultsChart = ({quiz}) => {
    const [data, setData] = useState(quiz?.subjects.map(subject => {
        return {
            name: LocalName.getName(subject),
            maxPoint: subject.maxPoint,
            resultPoint: subject.resultPoint
        }
    }));

    return (
        <Flex style={{height: 250, maxWidth: 600, width: "100%", position: "relative", borderRadius: 8, padding: 20, backgroundColor: clrs.white}}>
            <ResponsiveContainer>
                <MyText strong><IntlMessage id={'quiz.statsBySubjects'}/></MyText>
                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="resultPoint" name={<IntlMessage id={'quiz.statsBySubjects.resultPoint'}/>} fill={clrs.green} />
                    <Bar dataKey="maxPoint" name={<IntlMessage id={'quiz.statsBySubjects.maxPoint'}/>} fill={clrs.blue} />

                </BarChart>
            </ResponsiveContainer>
        </Flex>
    );
};

export default SubjectResultsChart;