import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const UserService = {};
UserService.getUserByToken = async () => {
    let data = new FormData();
    const token = localStorage.getItem(ACCESS_TOKEN);
    data.append("token", token);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/get/token",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.register = async (
    username,
    password,
    telNumber,
    email,
    firstName,
    secondName
) => {
    let data = new FormData();
    data.append("username", username);
    data.append("password", password);
    data.append("telNumber", telNumber);
    data.append("email", email);
    data.append("firstName", firstName);
    data.append("secondName", secondName);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/register",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

UserService.updatePersonalData = async (
    telNumber,
    firstName,
    secondName,
    middleName,
    gender,
    regionRegistered,
    townRegistered,
    schoolName,
    studentClass
) => {
    let data = new FormData();
    data.append("telNumber", telNumber);
    data.append("firstName", firstName);
    data.append("secondName", secondName);
    if (middleName !== null) {
        data.append("middleName", middleName);
    }
    if (gender !== null) {
        data.append("gender", gender);
    }
    if (regionRegistered !== null) {
        data.append("regionRegistered", regionRegistered);
    }
    if (townRegistered !== null) {
        data.append("townRegistered", townRegistered);
    }
    if (schoolName !== null) {
        data.append("schoolName", schoolName);
    }
    if (studentClass !== null) {
        data.append("studentClass", studentClass);
    }

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/update/personalData",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.sendChangePasswordMessage = async (
    username,
    telNumber,
) => {
    let data = new FormData();
    data.append("username", username);
    data.append("telNumber", telNumber);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/send/change-password",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

UserService.checkChangePasswordToken = async (
    token
) => {
    let data = new FormData();
    data.append("token", token);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/check/change-password-token",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

UserService.changePasswordOfUser = async (
    password,
    token
) => {
    let data = new FormData();
    data.append("password", password);
    data.append("token", token);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/change/password",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

UserService.changeEmailOfUser = async (
    email
) => {
    let data = new FormData();
    data.append("email", email);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/change/email",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}


UserService.resendEmailConfirmationMessage = async () => {

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/resend/email-confirmation",
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.confirmEmail = async (
    token
) => {

    return axios({
        method: "get",
        url: API_BASE_URL + `/api/user/confirm/${token}`,
        headers: {
            "Content-Type": "application/json",
        }
    });
}
export default UserService;