import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const SubscriptionService = {};
SubscriptionService.getMyTransactions = async (
    page, limit
) => {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/user/subscription/get/my/transactions?page=${page - 1}&limit=${limit}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        },
    });

    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

SubscriptionService.uploadAccount = async (
    file, subscriptionType
) => {
    let data = new FormData();
    data.append("file", file);
    data.append("subscriptionType", subscriptionType);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/subscription/upload/account",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default SubscriptionService;