import {configureStore} from "@reduxjs/toolkit";
import {REACT_APP_NODE_ENV} from "../constants/env";
import user from './slices/userSlice';
import quiz from './slices/quizSlice';
import platformNav from './slices/platform/PlatformNavSlice';
import timeStatus from './slices/platform/timeStatusSlice';
export default configureStore({
    reducer: {
        platformNav: platformNav,
        timeStatus: timeStatus,
        user: user,
        quiz: quiz,
    },
    devTools: REACT_APP_NODE_ENV !== 'production'
});