import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const QuizQuestionService = {};
QuizQuestionService.toogleQuestion = async (
    quizId,
    subjectId,
    questionId,
    answerId,
    isSelected,
) => {
    let data = new FormData();
    data.append("quizId", quizId);
    data.append("subjectId", subjectId);
    data.append("questionId", questionId);
    data.append("answerId", answerId);
    data.append("isSelected", isSelected);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/quiz/question/toggle-select",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
QuizQuestionService.selectDndAnswer = async (
    quizId,
    subjectId,
    questionId,
    answerId,
    dndAnswerId,
) => {
    let data = new FormData();
    data.append("quizId", quizId);
    data.append("subjectId", subjectId);
    data.append("questionId", questionId);
    data.append("answerId", answerId);
    data.append("dndAnswerId", dndAnswerId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/quiz/question/select/dnd",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
QuizQuestionService.toogleQuestionOfContext = async (
    quizId,
    subjectId,
    contextId,
    questionId,
    answerId,
    isSelected,
) => {
    let data = new FormData();
    data.append("quizId", quizId);
    data.append("subjectId", subjectId);
    data.append("contextId", contextId);
    data.append("questionId", questionId);
    data.append("answerId", answerId);
    data.append("isSelected", isSelected);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/quiz/question/toggle-select/context",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default QuizQuestionService;