import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {changeTimeStatus} from "../../../../../../store/slices/platform/timeStatusSlice";
import {Flex, message, Progress, Statistic} from "antd";
import IntlMessage from "../../../../../../components/IntlMessage/IntlMessage";
import QuizService from "../../../../../../services/QuizService";
import {clearUser} from "../../../../../../store/slices/userSlice";
import MyText from "../../../../../../components/UI/Text/MyText";
import {clrs} from "../../../../../../constants/colors";

const TimeStatusMobile = ({deadline, quiz, setQuiz}) => {
    const dispatch = useDispatch();

    function handleOnFinish() {
        // You can add additional logic when the countdown reaches zero
        message.loading(<IntlMessage id={'quiz.finishingTest'}/>, 0);
        QuizService.finishQuiz(quiz.id)
            .then((result) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setQuiz(result.data);
            })
            .catch((result) => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    return (
        <Flex vertical align={"center"} justify={"center"} gap={5} style={{backgroundColor: "white", borderRadius: 8}}>
            <MyText style={{fontWeight: 400}}><IntlMessage id={'quiz.timeLeft'}/></MyText>
            <Statistic.Countdown
                value={deadline}
                onFinish={handleOnFinish}
                // valueStyle={{ fontSize: '1rem' }}
            />
        </Flex>
    );
};

export default TimeStatusMobile;