import React from 'react';
import {useTranslation} from "react-i18next";
import {Flex, Form, Input, message} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useWindowSize} from "@uidotdev/usehooks";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import AuthService from "../../services/AuthService";
import {getUserByToken} from "../../store/slices/userSlice";
import Block from "../components/Block/Block";
import {clrs} from "../../constants/colors";
import classes from "../global_styles.module.css";
import FormItem from "antd/lib/form/FormItem";
import {KeyOutlined, MailOutlined, SolutionOutlined, UserOutlined} from "@ant-design/icons";
import RedButton from "../../components/UI/Buttons/RedButton";
import PhoneIcon from "../../components/icons/PhoneIcon";
import {UsernameValidator} from "../../utils/UsernameValidator";
import {EmailValidator} from "../../utils/EmailValidator";
import UserService from "../../services/UserService";
import {PasswordValidator} from "../../utils/PasswordValidator";
import BrainBrewText from "../../components/BrainBrew/BrainBrewText";

const RegistrationPage = () => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const username = Form.useWatch('username', form);
    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);
    const telNumber = Form.useWatch('telNumber', form);
    const email = Form.useWatch('email', form);
    const firstName = Form.useWatch('firstName', form);
    const secondName = Form.useWatch('secondName', form);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const size = useWindowSize();

    function handleOnSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                await UserService.register(
                    username,
                    password,
                    telNumber,
                    email,
                    firstName,
                    secondName
                )
                    .then(async (result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        navigate('/login')
                    })
                    .catch((result) => {
                        console.log(result)
                        message.destroy();
                        if (result.response.data === 'User already exists') {
                            message.error(<IntlMessage id={'userAlreadyExists'}/>, 5);
                        } else {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        }
                    })
            })
            .catch(() => {

            })
    }

    return (
        <Flex vertical gap={50} align={"center"} justify={"center"} style={{width: "100%", minHeight: "100vh", padding: size.width > 768 ? 100 : 20}}>
            <BrainBrewText/>
            <Block gap={25} vertical style={{backgroundColor: clrs.yellow, maxWidth: 700, width: "100%", padding: size.width > 768 ? "25px 50px" : "25px 20px"}}>
                <p style={{color: clrs.white, fontWeight: "bold"}}><IntlMessage id={'registration'}/></p>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} vertical gap={15}>
                    <Form form={form} style={{maxWidth: 500}} className={classes.form}>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (UsernameValidator.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('usernameRules')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="username">
                            <Input onInput={e => e.target.value = e.target.value.toLowerCase()} placeholder={t('username')} prefix={<UserOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (PasswordValidator.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('passwordRules')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="password">
                            <Input.Password placeholder={t('password')} prefix={<KeyOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('confirmPasswordError')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="confirmPassword">
                            <Input.Password placeholder={t('confirmPassword')} prefix={<KeyOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="telNumber">
                            <Input placeholder={t('telNumber')} prefix={<PhoneIcon style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (EmailValidator.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('emailRules')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="email">
                            <Input onInput={e => e.target.value = e.target.value.toLowerCase()} placeholder={t('email')} prefix={<MailOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="firstName">
                            <Input placeholder={t('firstName')} prefix={<SolutionOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="secondName">
                            <Input placeholder={t('secondName')} prefix={<SolutionOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <RedButton onClick={handleOnSubmit}>
                            <IntlMessage id={'registration'}/>
                        </RedButton>
                    </Form>
                    <Flex vertical={size.width < 500} style={{width: "100%", maxWidth: 500}} gap={20} align={"center"} justify={"space-between"}>
                        <Link to={'/login'} style={{color: clrs.blacker2, fontWeight: "bold"}}>
                            <IntlMessage id={'i have an account'}/>
                        </Link>
                    </Flex>
                </Flex>
            </Block>

        </Flex>
    );
};

export default RegistrationPage;