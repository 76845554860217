import React from 'react';
import { Button } from 'antd';
import classes from './buttons.module.css';
import PropTypes from "prop-types";

const SteveBloxButton = (props) => {
    return (
        <Button {...props} className={[props.selected && classes.steveBloxBtnSelected, classes.steveBloxBtn].join(" ")}>
            {props.children}
        </Button>
    );
};

SteveBloxButton.propTypes = {
    ...Button.propTypes, // Use Ant Design Button propTypes
    selected: PropTypes.bool,
};

export default SteveBloxButton;
