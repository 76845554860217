import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg height={35} viewBox="0 0 52.43 57.03">
            <g>
                <g>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m50.35,3.21c-3.58-1.1-8.21-1.01-11.91-1.48C33.87,1.16,29.31.58,24.75,0c-.47-.06-.66.32-.61.69-3.92,2.26-7.93,4.65-11.59,7.27-.27.19-.11.55.16.54-.19.19-.2.55.14.63,2.14.52,4.3.99,6.45,1.46.82.18,1.58.51,2.34.08,1.24-.69,2.06-3.11,2.61-4.28.73-1.55,1.3-3.15,1.78-4.79.01-.05,0-.09.01-.13,5.76.73,11.51,1.46,17.27,2.21,1.16.15,3.9-.06,4.89.64,2.33,1.66,2.17,3.94,1.67,6.29-.62,2.9-1.24,5.8-1.86,8.7-1.38,6.44-2.77,12.88-4.14,19.33-.62,2.9-1.23,5.8-1.85,8.7-.64,3.03-.48,7.41-3.97,7.89-3.06.42-7.23-.87-10.18-1.3-3.14-.46-6.27-.96-9.38-1.52-2.81-.5-5.61-1.04-8.4-1.62-3.99-.83-8.52-.66-8.04-6.18.24-2.73,1.68-5.72,2.46-8.37.91-3.1,1.82-6.2,2.74-9.3,1.83-6.21,3.66-12.4,5.16-18.69.1-.41-.49-.57-.63-.17-3.04,8.53-5.5,17.26-8.09,25.93-1.16,3.87-2.78,7.83-3.49,11.8-.23,1.28-.46,2.7.5,3.78,1.69,1.91,5.85,1.96,8.06,2.43,4.03.85,8.08,1.61,12.14,2.3,3.5.59,7.01,1.12,10.53,1.59,2,.27,7.45,1.93,9.38.65,1.7-1.13,1.65-4.26,2-5.91.83-3.87,1.65-7.73,2.48-11.6,1.92-9.02,3.83-18.05,5.75-27.07.63-2.94,3.12-7.59-.7-8.76Zm-28.45,5.16c-1.94,2.23-6.29.46-8.84.04-.02,0-.03,0-.05,0,4.05-1.85,8.07-4.29,11.76-6.8-.64,2.19-1.41,5.07-2.88,6.77Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m37.45,44.6c-10.15-1.07-20.05-2.79-30.12-4.36-.33-.05-.47.4-.14.5,9.64,2.97,20.19,4.4,30.26,4.93.7.04.67-1,0-1.07Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m38.66,37.68c-4.11-1.93-10.15-1.81-14.64-2.49-5.25-.8-10.51-1.61-15.78-2.3-.32-.04-.4.51-.08.57,4.88.92,9.77,1.74,14.66,2.57,2.45.41,4.9.82,7.35,1.23,1.22.21,2.45.41,3.67.62,1.21.34,2.43.4,3.66.18-.08.19-.07.43.1.63.04.05.08.09.12.14.22.25.63.21.85,0,.07-.06.13-.12.2-.19.27-.25.23-.79-.12-.96Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m40.32,28.99c-9.74-1.96-19.75-3.22-29.63-4.24-.36-.04-.44.57-.09.63,9.81,1.51,19.6,3.24,29.42,4.67.68.1,1-.92.29-1.06Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m42.65,18.23c-9.43-.64-18.84-1.46-28.26-2.25-.32-.03-.4.52-.08.56,9.4,1.28,18.87,2.19,28.34,2.92.79.06.79-1.18,0-1.23Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m47.46,5.09c-1.79-.44-3.71-.6-5.55-.57-.26,0-.25.38,0,.4,1.82.16,3.58.45,5.38.8.4.08.58-.52.17-.62Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m47.94,6.41c-1.16-.18-2.38-.15-3.56-.21-.36-.02-.35.52,0,.55,1.14.1,2.32.33,3.47.31.36,0,.47-.59.09-.65Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m10.77,49.01c-2.17-.65-4.32-1.1-6.44-1.95-.27-.11-.47.35-.2.48,2.04.95,4.32,1.85,6.57,2.05.34.03.38-.48.08-.57Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m13.59,48.26c-2.06-.91-4.32-1.4-6.55-1.7-.29-.04-.43.4-.13.46,2.23.4,4.29,1.1,6.41,1.88.37.13.63-.48.27-.64Z"/>
                </g>
            </g>
        </svg>
    );
};

const RusIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default RusIcon;