import React from 'react';
import { Button } from 'antd';
import classes from './buttons.module.css';

const HoverButton = (props) => {
    return (
        <Button {...props} className={classes.hoverBtn}>
            {props.children}
        </Button>
    );
};

HoverButton.propTypes = {
    ...Button.propTypes, // Use Ant Design Button propTypes
};

export default HoverButton;