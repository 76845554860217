import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";


const svg = () => {
    return (
        <svg height="15" viewBox="0 0 21 15" width="21" xmlns="http://www.w3.org/2000/svg">
            <linearGradient id="aaaaa" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0" stopColor="#fff"/>
                <stop offset="1" stopColor="#f0f0f0"/>
            </linearGradient>
            <linearGradient id="bbbbb" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0" stopColor="#0c47b7"/>
                <stop offset="1" stopColor="#073da4"/>
            </linearGradient>
            <linearGradient id="ccccc" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0" stopColor="#e53b35"/>
                <stop offset="1" stopColor="#d32e28"/>
            </linearGradient>
            <g fill="none" fillRule="evenodd">
                <path d="m0 0h21v15h-21z" fill="url(#aaaaa)"/>
                <path d="m0 5h21v5h-21z" fill="url(#bbbbb)"/>
                <path d="m0 10h21v5h-21z" fill="url(#ccccc)"/>
                <path d="m0 0h21v5h-21z" fill="url(#aaaaa)"/>
            </g>
        </svg>
    );
};

const RusFlagIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default RusFlagIcon;