import React from 'react';
import classes from './block.module.css';
import {Flex} from "antd";
const Block = (props) => {
    return (
        <Flex className={classes.block} {...props}>
            {props.children}
        </Flex>
    );
};

Block.propTypes = {
    ...Flex.propTypes,
};

export default Block;