import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import QuizService from "../../services/QuizService";
import {clearUser} from "./userSlice";

let initialState = {
    quiz: null,
    isLoading: true,
    error: null,
}

export const getQuizById = createAsyncThunk(
    'getQuizById',
    async ({quizId}, { getState, rejectWithValue, dispatch }) => {
        try {
            return await QuizService.getQuizById(quizId);
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('Authorization');
                dispatch(clearUser());
            }
            return rejectWithValue(error?.response);
        }
    },
);

const quizSlice = createSlice({
    name: 'quizSlice',
    initialState,
    reducers: {
        clearQuiz(state) {
            state.quiz = null;
        },
        setQuizState(state, action) {
            state.quiz = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuizById.pending, (state) => {
                state.isLoading = true;
                state.quiz = null;
                state.error = null;
            })
            .addCase(getQuizById.fulfilled, (state, action) => {
                state.quiz = action.payload.data;
                state.isLoading = false;
            })
            .addCase(getQuizById.rejected, (state, action) => {
                state.isLoading = false;
                state.quiz = null;
                state.error = action.payload;
            })
    }
});

export const {
    clearQuiz,
    setQuizState
} = quizSlice.actions;

export default quizSlice.reducer;