import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m2.00146 4.00134c0-1.10457.89544-2 2-2h7.99864c1.1046 0 2 .89543 2 2v6.12986l-1-1.69549v-4.43437c0-.55228-.4477-1-1-1h-7.99864c-.55228 0-1 .44772-1 1v7.99866c0 .5523.44772 1 1 1h2.3062l-.04019.0681c-.16718.2835-.25917.6039-.26693.9319h-1.99908c-1.10457 0-2-.8954-2-2z"/>
                <path d="m7.19234 11.5.58979-1h-.77896c-.27614 0-.5.2239-.5.5s.22386.5.5.5z"/>
                <path
                    d="m8.96155 8.50029.30817-.52249c.10835-.18373.24247-.34339.39494-.4774l-2.66141-.0004c-.27614-.00004-.50003.22378-.50008.49993-.00004.27614.22379.50003.49993.50007z"/>
                <path d="m5.5 5c0 .41421-.33579.75-.75.75s-.75-.33579-.75-.75.33579-.75.75-.75.75.33579.75.75z"/>
                <path
                    d="m4.75 8.75189c.41421 0 .75-.33578.75-.75 0-.41421-.33579-.75-.75-.75s-.75.33579-.75.75c0 .41422.33579.75.75.75z"/>
                <path d="m5.5 11c0 .4142-.33579.75-.75.75s-.75-.3358-.75-.75.33579-.75.75-.75.75.3358.75.75z"/>
                <path
                    d="m7.00317 4.5c-.27614 0-.5.22386-.5.5s.22386.5.5.5h4.47413c.2762 0 .5-.22386.5-.5s-.2238-.5-.5-.5z"/>
                <path
                    d="m10.7349 8.03453c.2508-.06614.5255-.03894.7663.09507.1546.08604.281.20951.3675.35621l3.0022 5.09029c.1341.2273.1601.4829.095.7158-.0652.233-.2223.4451-.4624.5787-.1518.0844-.3247.1294-.5014.1294h-6.00447c-.28033 0-.53063-.1099-.71061-.2848-.17972-.1745-.28702-.411-.28702-.6699 0-.1637.04402-.3254.12882-.4692l3.00228-5.09029c.1345-.22812.3532-.38517.6038-.45128zm.7651 1.46767c0-.27614-.2239-.5-.5-.5s-.5.22386-.5.5v1.9958c0 .2761.2239.5.5.5s.5-.2239.5-.5zm-.5 4.9978c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75-.75.3358-.75.75.3358.75.75.75z"/>
            </g>
        </svg>
    );
};

const ComplaintIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default ComplaintIcon;