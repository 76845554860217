import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const StatementService = {};

StatementService.save = async (
    title, body, statementType
) => {
    let data = new FormData();
    data.append("title", title);
    data.append("body", body);
    data.append("statementType", statementType);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/statement/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default StatementService;