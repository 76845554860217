import React from 'react';
import classes from './buttons.module.css';
import {Button} from "antd";

const NorthButton = (props) => {
    return (
        <Button {...props} className={classes.northButton}>
            <span>{props.children}</span>
        </Button>
    );
};

NorthButton.propTypes = {
    ...Button.propTypes, // Use Ant Design Button propTypes
};

export default NorthButton;