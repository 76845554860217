import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg height={35} viewBox="0 0 50.86 40.38">
            <g>
                <g>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m48.99.05C33.54,5.07,18.33,11.33,3.42,17.75c-.27.12-.43.55-.33.82,2.34,6.37,4.16,12.88,5.51,19.52-2.66-2.95-5.29-5.95-8.19-8.64-.18-.17-.53.06-.36.28,2.78,3.69,6.01,7.04,9.07,10.49.38.43.95-.04.87-.51-.98-5.63-2.25-11.18-3.92-16.65-1.21-3.97-1.06-4.4,2.75-5.9,2.7-1.06,5.38-2.14,8.08-3.21C27.68,9.67,38.64,5.87,49.43,1.63c.96-.38.59-1.91-.44-1.58Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m27.25,29.5c-1.17-1.12-2.79-1.78-4.3-2.46.3-2.37.58-4.75.79-7.13.09-1-1.41-.95-1.53,0-.28,2.14-.51,4.28-.72,6.43-1.81-.89-3.61-1.77-5.43-2.65-.55-.27-1.03.52-.49.83,1.92,1.1,3.84,2.18,5.77,3.26-.17,1.8-.33,3.6-.51,5.41-.08.85,1.22.82,1.32,0,.19-1.52.39-3.05.59-4.57,1.26.77,2.56,1.55,3.95,1.84.53.11.99-.54.56-.95Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m37.81,20.42c-1.09-.09-2.27.38-3.3.75-.46-.97-.96-1.91-1.52-2.77-.29-.43-.98-.17-.85.36.24.98.58,1.98.97,2.96-.68.29-1.35.59-2.01.93-.68.35-.08,1.33.59,1.02.62-.28,1.26-.54,1.9-.77.42.98.86,1.95,1.28,2.88.35.78,1.74.21,1.4-.59-.38-.9-.77-1.84-1.19-2.78.02,0,.04-.01.06-.02.99-.28,2.06-.37,2.98-.81.6-.29.25-1.12-.32-1.16Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m50.84,30.23c-1.05-4.17-2.02-8.31-2.91-12.49,0-.04-.01-.08-.02-.11-.46-2.17-.9-4.34-1.3-6.53-.12-.65-1.22-.51-1.16.16.17,1.67.38,3.35.63,5.03-.59-.3-1.2-.57-1.78-.86-1.13-.57-2.27-1.1-3.43-1.61-.7-.31-1.29.63-.61,1.05,1.91,1.19,4.01,2.65,6.15,3.48.71,4.17,1.69,8.3,2.98,12.28.3.91,1.68.54,1.44-.4Z"/>
                </g>
            </g>
        </svg>
    );
};

const MathIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default MathIcon;