import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg fill="none" height="35" viewBox="0 0 24 24" width="35" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="m2 12 5.25 5s1.41939-1.6222 2.625-3m6.125-7-3.5 4"/>
                <path d="m8 12 5.25 5 8.75-10"/>
            </g>
        </svg>
    );
};

const DoubleCheckIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default DoubleCheckIcon;