import React from 'react';
import classes from "../../quiz.module.css";
import {Checkbox, Flex, Image} from "antd";
import parse from "html-react-parser";
import {API_BASE_URL} from "../../../../../../constants/api";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import {clrs} from "../../../../../../constants/colors";
import style from './style.module.css';

const MyCheckboxButton = ({answer, quiz, handleToogleQuestion, getQuestion}) => {
    return (
        <Checkbox
            checked={getQuestion().answers.find(a => a.id === answer.id)?.isSelected}
            onChange={(e) => {
                handleToogleQuestion(e)
            }}
            disabled={quiz.isFinished}
            className={[quiz.isFinished && (answer.isCorrect ? classes.correntAnswer : answer.isSelected && classes.incorrectAnswer), style.check, answer.isSelected && style.checkSelected].join(" ")}
            key={answer.id} value={answer.id}>
            <Flex align={"center"} justify={"center"} gap={10}>
                <Flex align={"flex-start"} justify={"flex-start"}
                      gap={10}
                      vertical wrap={"wrap"}>
                    {answer.answerText && answer.answerText !== 'null' &&
                        <span>{parse(answer.answerText)}</span>}
                    <Flex wrap={"wrap"} align={"flex-start"}
                          justify={"flex-start"} gap={20}>
                        {
                            answer.images.map((answerImage, i) => {
                                return (
                                    <Image preview={false} key={answerImage.id} style={{
                                        maxHeight: 300,
                                        maxWidth: 300
                                    }}
                                           src={API_BASE_URL + "/api/file/get/" + answerImage.fileName}/>
                                )
                            })
                        }
                    </Flex>
                </Flex>
                {quiz.isFinished && (answer.isCorrect ?
                    <CheckCircleFilled
                        style={{color: clrs.green}}/> : answer.isSelected &&
                    <CloseCircleFilled style={{color: clrs.red}}/>)}
            </Flex>

        </Checkbox>
    );
};

export default MyCheckboxButton;