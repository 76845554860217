import React from 'react';
import {Flex, Form, Input, message, Select} from "antd";
import {clrs} from "../../../../../constants/colors";
import {useDispatch, useSelector} from "react-redux";
import cl from '../../../../global_styles.module.css';
import FormItem from "antd/lib/form/FormItem";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import HorizontalDivider from "../../../../../components/Divider/HorizontalDivider";
import SteveBloxButton from "../../../../../components/UI/Buttons/SteveBloxButton";
import UserService from "../../../../../services/UserService";
import {clearUser, setUserState} from "../../../../../store/slices/userSlice";

const ProfileSettings = () => {
    const {user} = useSelector(state => state.user);
    const [form] = Form.useForm();
    const telNumber = Form.useWatch('telNumber', form);
    const firstName = Form.useWatch('firstName', form);
    const secondName = Form.useWatch('secondName', form);
    const middleName = Form.useWatch('middleName', form);
    const gender = Form.useWatch('gender', form);
    const regionRegistered = Form.useWatch('regionRegistered', form);
    const townRegistered = Form.useWatch('townRegistered', form);
    const schoolName = Form.useWatch('schoolName', form);
    const studentClass = Form.useWatch('studentClass', form);

    const dispatch = useDispatch();

    function handleOnSave() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                await UserService.updatePersonalData(
                    telNumber,
                    firstName,
                    secondName,
                    middleName,
                    gender,
                    regionRegistered,
                    townRegistered,
                    schoolName,
                    studentClass
                )
                    .then(async (result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'saved'}/>, 5);
                        dispatch(setUserState(result.data))
                    })
                    .catch((result) => {
                        console.log(result)
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        if (result.response.status === 401) {
                            message.destroy();
                            localStorage.removeItem('Authorization');
                            dispatch(clearUser())
                            message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                        }
                    })
            })
            .catch(() => {

            })
    }

    return (
        <Flex style={{width: "100%", padding: 15, backgroundColor: clrs.white, borderRadius: 8}}>
            <Form className={cl.form} form={form}
                  initialValues={{
                      telNumber: user?.personalData?.telNumber,
                      firstName: user?.personalData?.firstName,
                      secondName: user?.personalData?.secondName,
                      middleName: user?.personalData?.middleName,
                      gender: user?.personalData?.gender,
                      regionRegistered: user?.personalData?.regionRegistered,
                      townRegistered: user?.personalData?.townRegistered,
                      schoolName: user?.personalData?.schoolName,
                      studentClass: user?.personalData?.studentClass,
                  }}
            >
                <FormItem
                    rules={[
                        {
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                    ]}
                    label={<IntlMessage id={'telNumber'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="telNumber"
                >
                    <Input rootClassName={cl.formInput}/>
                </FormItem>
                <FormItem
                    rules={[
                        {
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                    ]}
                    label={<IntlMessage id={'firstName'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="firstName"
                >
                    <Input rootClassName={cl.formInput}/>
                </FormItem>
                <FormItem
                    rules={[
                        {
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                    ]}
                    label={<IntlMessage id={'secondName'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="secondName"
                >
                    <Input rootClassName={cl.formInput}/>
                </FormItem>
                <FormItem
                    label={<IntlMessage id={'middleName'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="middleName"
                >
                    <Input rootClassName={cl.formInput}/>
                </FormItem>
                <HorizontalDivider/>
                <FormItem
                    label={<IntlMessage id={'settings.gender'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="gender"
                >
                    <Select
                        style={{maxWidth: 300, width: "100%"}}
                        options={[
                            {value: true, label: <IntlMessage id={'settings.gender.true'}/>},
                            {value: false, label: <IntlMessage id={'settings.gender.false'}/>},
                        ]}
                    />
                </FormItem>
                <FormItem
                    label={<IntlMessage id={'settings.regionRegistered'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="regionRegistered"
                >
                    <Input rootClassName={cl.formInput}/>
                </FormItem>
                <FormItem
                    label={<IntlMessage id={'settings.townRegistered'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="townRegistered"
                >
                    <Input rootClassName={cl.formInput}/>
                </FormItem>
                <FormItem
                    label={<IntlMessage id={'settings.schoolName'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="schoolName"
                >
                    <Input rootClassName={cl.formInput}/>
                </FormItem>
                <FormItem
                    label={<IntlMessage id={'settings.studentClass'}/>}
                    labelCol={{span: 24}}
                    className={cl.formInputBlock} name="studentClass"
                >
                    <Select
                        style={{maxWidth: 300, width: "100%"}}
                        options={[
                            {value: 'CLASS_5', label: '5'},
                            {value: 'CLASS_6', label: '6'},
                            {value: 'CLASS_7', label: '7'},
                            {value: 'CLASS_8', label: '8'},
                            {value: 'CLASS_9', label: '9'},
                            {value: 'CLASS_10', label: '10'},
                            {value: 'CLASS_11', label: '11'},
                        ]}
                    />
                </FormItem>
                <SteveBloxButton onClick={handleOnSave}>
                    <IntlMessage id={'save'}/>
                </SteveBloxButton>
            </Form>
        </Flex>
    );
};

export default ProfileSettings;