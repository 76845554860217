import React, {useEffect, useState} from 'react';
import TimeStatus from "../Quiz/components/TimeStatus/TimeStatus";
import SteveBloxButton from "../../../../components/UI/Buttons/SteveBloxButton";
import {Button, Checkbox, Flex, Form, Image, Input, message, Progress, Upload, Card as AntdCard} from "antd";
import {LeftOutlined, RightOutlined, UploadOutlined, UserOutlined} from "@ant-design/icons";
import BhargaButton from "../../../../components/UI/Buttons/BhargaButton";
import classes from "../../../global_styles.module.css";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import FormItem from "antd/lib/form/FormItem";
import Card from "../../../../components/LoadingComponents/Card";
import MyMasonry from "../../../../components/Masonry/MyMasonry";
import {useDispatch, useSelector} from "react-redux";
import StatsService from "../../../../services/StatsService";
import {clearUser} from "../../../../store/slices/userSlice";
import profile_user from "../../../../images/profile_user.jpg";
import MyText from "../../../../components/UI/Text/MyText";
import {clrs} from "../../../../constants/colors";
import Calendar from "react-calendar";
import './calendar.css';
import {useWindowSize} from "@uidotdev/usehooks";
import HoverButton from "../../../../components/UI/Buttons/HoverButton";
import {useNavigate} from "react-router-dom";

const HomePage = () => {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [stats, setStats] = useState(null);

    const {user} = useSelector(state => state.user);
    const {subscription} = useSelector(state => state.user.user);

    const size = useWindowSize();

    useEffect(() => {
        StatsService.getStats()
            .then((result) => {
                setLoading(false);
                setStats(result.data);
            })
            .catch((result) => {
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }, []);

    if (loading) {
        return (
            <Flex style={{width: "100%"}} align={"center"} justify={"center"} wrap={"wrap"} gap={10}>
                <MyMasonry>
                    <Card type={'horizontal-circle'}/>
                    <Card type={'calendar'}/>
                    <Card type={'big-card'}/>
                    <Card type={'horizontal'}/>
                    <Card type={'horizontal'}/>
                    <Card type={'horizontal'}/>
                </MyMasonry>
            </Flex>
        );
    }

    return (
        <Flex style={{width: "100%"}} align={"center"} justify={"center"} wrap={"wrap"} gap={10}>
            <MyMasonry>
                <Flex style={{width: "100%", minHeight: 350, overflow: "hidden", textAlign: "center", padding: 20, backgroundColor: clrs.white, borderRadius: 8}} align={"center"} justify={"center"} gap={20} vertical>
                    <Image
                        preview={false}
                        style={{maxWidth: 250, width: "100%", borderRadius: "100%"}}
                        src={profile_user}
                    />
                    <MyText style={{color: clrs.blacker2}} strong>
                        {user.personalData?.fullName}
                    </MyText>
                </Flex>
                <Flex style={{width: "100%", minHeight: 350, overflow: "hidden", textAlign: "center", backgroundColor: clrs.white, borderRadius: 8}} align={"center"} justify={"center"} gap={20} vertical>
                    {
                        subscription.isValid ?
                            <AntdCard style={{color: clrs.white, height: 350, width: "100%", textAlign: "center", backgroundColor: clrs.yellow, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Flex align={"center"} justify={"center"} vertical gap={20}>
                                    {/*<p style={{fontSize: "1.4rem", fontWeight: 400}}>Отсутствует активная подписка</p>*/}
                                    <p style={{fontSize: "1.2rem", fontWeight: 500}}><IntlMessage id={'subscription.currentSubscription'}/></p>
                                    <p style={{fontSize: "1rem", fontWeight: 500}}>до</p>
                                    <p style={{fontSize: "2rem", fontWeight: "bold"}}>{new Date(subscription.endedAt).toLocaleDateString()}</p>
                                    <BhargaButton
                                        onClick={() => {navigate('/p/quizzes/pass')}}
                                    ><IntlMessage id={'passQuiz'}/></BhargaButton>
                                </Flex>
                            </AntdCard>
                            :
                            <AntdCard style={{color: clrs.white, height: 350, width: "100%", textAlign: "center", backgroundColor: clrs.blacker2, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Flex align={"center"} justify={"center"} vertical gap={20}>
                                    {/*<p style={{fontSize: "1.4rem", fontWeight: 400}}>Отсутствует активная подписка</p>*/}
                                    <p style={{fontSize: "1rem", fontWeight: 400}}><IntlMessage id={'subscription.currentSubscription'}/></p>
                                    {/*<p style={{fontSize: "1rem", fontWeight: 500}}>до</p>*/}
                                    <p style={{fontSize: "1.2rem", fontWeight: "bold"}}><IntlMessage id={'subscription.noSubscription'}/></p>
                                    <SteveBloxButton onClick={() => {navigate('/p/subscription')}}>
                                        <IntlMessage id={'subscribe'}/>
                                    </SteveBloxButton>
                                </Flex>
                            </AntdCard>
                    }

                </Flex>
                <Flex style={{width: "100%", minHeight: 350, overflow: "hidden", textAlign: "center", padding: 20, backgroundColor: clrs.white, borderRadius: 8}} align={"center"} justify={"center"} gap={20} vertical>
                    <Calendar locale={"ru-RU"}/>
                </Flex>
                <Flex style={{width: "100%", minHeight: 350, overflow: "hidden", textAlign: "center", padding: 20, backgroundColor: clrs.white, borderRadius: 8}} align={"center"} justify={"center"} gap={20} vertical>
                    <MyText strong><IntlMessage id={'platform.home.avgResult'}/></MyText>
                    <Progress size={200} type="circle" percent={stats?.averageResultPointOfFullQuizzes / stats?.maxPoint * 100} strokeColor={clrs.blue} format={() => `${stats?.averageResultPointOfFullQuizzes} / ${stats?.maxPoint}`} />
                </Flex>
                <Flex style={{width: "100%", minHeight: 350, overflow: "hidden", textAlign: "center", padding: 20, backgroundColor: clrs.white, borderRadius: 8}} align={"center"} justify={"center"} gap={20} vertical>
                    <MyText strong><IntlMessage id={'platform.home.bestResult'}/></MyText>
                    <Progress size={200} type="circle" percent={stats?.maxResultPointOfFullQuizzes / stats?.maxPoint * 100} strokeColor={clrs.blue} format={() => `${stats?.maxResultPointOfFullQuizzes} / ${stats?.maxPoint}`} />
                </Flex>

            </MyMasonry>
            {/*<Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={10}>*/}
            {/*    /!*<TimeStatus deadline={deadline} time={time}/>*!/*/}
            {/*    <SteveBloxButton>*/}
            {/*        <LeftOutlined />*/}
            {/*        <span>Предыдущий</span>*/}
            {/*    </SteveBloxButton>*/}
            {/*    <SteveBloxButton>*/}
            {/*        <span>Следующий</span>*/}
            {/*        <RightOutlined />*/}
            {/*    </SteveBloxButton>*/}
            {/*    <BhargaButton>*/}
            {/*        Закончить тест*/}
            {/*    </BhargaButton>*/}
            {/*</Flex>*/}
            {/*<Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={10}>*/}
            {/*    <Form form={form} className={classes.form}>*/}
            {/*        <FormItem*/}
            {/*            className={classes.formInputBlock}*/}
            {/*            label={<IntlMessage id={'files'}/>}*/}
            {/*            labelCol={{ span: 24 }}*/}
            {/*            name={"questionImages"}*/}
            {/*        >*/}
            {/*            <Upload*/}
            {/*                {...propsQuestionImages}*/}
            {/*                listType="picture"*/}
            {/*            >*/}
            {/*                <Button icon={<UploadOutlined />}><IntlMessage id={'upload'}/></Button>*/}
            {/*            </Upload>*/}
            {/*        </FormItem>*/}
            {/*    </Form>*/}
            {/*</Flex>*/}

        </Flex>
    );
};

export default HomePage;