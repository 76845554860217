import React from 'react';
import {Flex} from "antd";
import {clrs} from "../../constants/colors";
import Block from "../components/Block/Block";
import Header from "../components/Header/Header";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import MyButton from "../../components/UI/Buttons/MyButton";
import DefaultButton from "../../components/UI/Buttons/DefaultButton";
import bannerBack from '../../images/bannerBack.png';
import {useWindowSize} from "@uidotdev/usehooks";
import ruler from '../../images/ruler.png';
import section2Back from '../../images/homeSection2Back.png';
import section3Back from '../../images/homeSection3Back.png';
import footerBack from '../../images/footerBack.png';
import kazHistoryIcon from '../../images/subjectIcons/kazHistory.svg';
import matGramotnostIcon from '../../images/subjectIcons/matGramotnost.svg';
import gramotnostChIcon from '../../images/subjectIcons/gramotnostCh.svg';
import math from '../../images/subjectIcons/math.svg';
import physics from '../../images/subjectIcons/physics.svg';
import rus from '../../images/subjectIcons/rus.svg';
import eng from '../../images/subjectIcons/eng.svg';
import literature from '../../images/subjectIcons/literature.svg';
import france from '../../images/subjectIcons/france.svg';
import chemistry from '../../images/subjectIcons/chemistry.svg';
import kaz from '../../images/subjectIcons/kaz.svg';
import german from '../../images/subjectIcons/german.svg';
import geography from '../../images/subjectIcons/geography.svg';
import prava from '../../images/subjectIcons/prava.svg';
import informatika from '../../images/subjectIcons/informatika.svg';
import biology from '../../images/subjectIcons/biology.svg';
import worldHistory from '../../images/subjectIcons/worldHistory.svg';
import popoverImg from '../../images/popover.svg';
import {Fade, JackInTheBox} from "react-awesome-reveal";
import Footer from "../components/Footer/Footer";
import {useScroll} from "../../components/Scroll/Scroll";
import HorizontalDivider from "../../components/Divider/HorizontalDivider";
import classes from './home.module.css';
import {useNavigate} from "react-router-dom";

const HomePage = () => {
    const size = useWindowSize();
    const scroll = useScroll();
    const navigate = useNavigate();
    return (
        <Flex align={"center"} vertical gap={25} justify={"center"}
              style={{padding: "25px", width: "100%", backgroundColor: clrs.yellow}}>
            <Block vertical>
                <Header/>
                <Flex vertical gap={15} align={"flex-start"} justify={"center"} style={{
                    width: "100%",
                    padding: size.width > 1024 ? 100 : size.width > 425 ? 50 : 30,
                    position: "relative",
                    backgroundColor: clrs.blacker2,
                    minHeight: 500
                }}>

                    <Fade triggerOnce={true} cascade>
                        <p style={{zIndex: 10, color: clrs.white, fontSize: "2rem"}}>_______</p>
                        <p style={{zIndex: 10, color: clrs.white, fontSize: "1.2rem", fontWeight: 300}}><IntlMessage
                            id={'home.banner1'}/></p>
                        <p style={{zIndex: 10, color: clrs.white, fontSize: "2.2rem", fontWeight: 700}}><IntlMessage
                            id={'home.banner2'}/></p>
                        <p style={{zIndex: 10, color: clrs.yellow, fontSize: "1.2rem", fontWeight: 300}}><IntlMessage
                            id={'home.banner3'}/></p>
                        <DefaultButton onClick={() => {
                            navigate('/register')
                        }} style={{
                            boxShadow: "0px 0px 25px 2px rgba(0, 0, 0, 0.4)",
                            backgroundColor: clrs.white,
                            padding: size.width > 425 ? "10px 50px" : "10px 15px",
                            color: "black",
                            borderRadius: 40
                        }}><IntlMessage id={'home.bannerBtn'}/></DefaultButton>
                    </Fade>
                    {
                        size.width > 1024 &&
                        <img style={{position: "absolute", maxWidth: "400px", right: "7%"}} src={bannerBack} alt=""/>
                    }
                    <div style={{
                        width: 200,
                        height: 200,
                        top: size.width > 550 ? -100 : -150,
                        left: size.width > 550 ? -100 : -150,
                        position: "absolute",
                        borderRadius: "100%",
                        border: `25px solid ${clrs.blue}`
                    }}></div>
                    <div style={{
                        width: 150,
                        height: 150,
                        top: size.width > 550 ? -100 : -150,
                        left: "35%",
                        position: "absolute",
                        borderRadius: "100%",
                        border: `35px solid ${clrs.red}`
                    }}></div>
                    <div style={{
                        width: 150,
                        height: 150,
                        top: 50,
                        right: size.width > 550 ? -100 : -150,
                        position: "absolute",
                        borderRadius: "100%",
                        border: `20px solid ${clrs.white}`
                    }}></div>
                    <div style={{
                        width: 150,
                        height: 150,
                        bottom: -120,
                        left: "15%",
                        position: "absolute",
                        borderRadius: "100%",
                        border: `20px solid ${clrs.yellow}`
                    }}></div>
                </Flex>
            </Block>

            <Block id={'ent'} gap={25} justify={"space-between"} align={"flex-start"}
                   style={{backgroundColor: clrs.white, padding: "50px 25px", position: "relative"}}>
                <img style={{width: "1440px", top: 0, position: "absolute"}} src={ruler} alt=""/>

                {
                    size.width > 1024 &&
                    <img src={section2Back} style={{width: 500}} alt=""/>
                }
                <Flex gap={35} style={{position: "relative", width: "100%"}} align={"center"} justify={"center"}
                      vertical>
                    <Fade triggerOnce={true} style={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 35
                    }}>
                        <p style={{fontSize: "1.8rem", fontWeight: "bold", textAlign: "center"}}><IntlMessage
                            id={'home.mandatorySubjects'}/></p>
                        <Flex justify={size.width > 500 ? "space-between" : "center"} align={"center"} onClick={() => {
                            navigate('/p/quizzes/pass')
                        }} style={{
                            cursor: "pointer",
                            fontSize: size.width > 500 ? "1.8rem" : "1.5rem",
                            textAlign: size.width > 500 ? "inherit" : "center",
                            maxWidth: 600,
                            padding: size.width > 500 ? "25px 50px" : "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.blue,
                            color: clrs.white
                        }}>
                            <p><IntlMessage id={'kazHistory'}/></p>
                            {
                                size.width > 500 &&
                                <img style={{width: size.width > 500 ? 70 : 50}} src={kazHistoryIcon} alt=""/>
                            }
                        </Flex>
                        <Flex justify={size.width > 500 ? "space-between" : "center"} onClick={() => {
                            navigate('/p/quizzes/pass')
                        }} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: size.width > 500 ? "1.8rem" : "1.5rem",
                            textAlign: size.width > 500 ? "inherit" : "center",
                            maxWidth: 600,
                            padding: size.width > 500 ? "25px 50px" : "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.red,
                            color: clrs.white
                        }}>
                            <p><IntlMessage id={'matGramotnost'}/></p>
                            {
                                size.width > 500 &&
                                <img style={{width: size.width > 500 ? 70 : 50}} src={matGramotnostIcon} alt=""/>
                            }
                        </Flex>
                        <Flex justify={size.width > 500 ? "space-between" : "center"} onClick={() => {
                            navigate('/p/quizzes/pass')
                        }} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: size.width > 500 ? "1.8rem" : "1.5rem",
                            textAlign: size.width > 500 ? "inherit" : "center",
                            maxWidth: 600,
                            padding: size.width > 500 ? "25px 50px" : "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.green,
                            color: clrs.white
                        }}>
                            <p><IntlMessage id={'gramotnostCh'}/></p>
                            {
                                size.width > 500 &&
                                <img style={{width: size.width > 500 ? 70 : 50}} src={gramotnostChIcon} alt=""/>
                            }
                        </Flex>
                    </Fade>

                </Flex>
            </Block>

            <Block gap={25} justify={"flex-start"} align={"flex-start"}
                   style={{backgroundColor: clrs.blacker2, padding: "25px 50px", position: "relative"}} vertical>
                <img style={{width: "1440px", zIndex: 0, top: 25, position: "absolute"}} src={section3Back} alt=""/>
                <p style={{fontSize: "1.8rem", zIndex: 10, color: clrs.white, fontWeight: "bold", textAlign: "center"}}>
                    <IntlMessage id={'home.profileSubjects'}/>
                </p>
                <Flex wrap={"wrap"} justify={"space-around"} align={"flex-start"} gap={20}
                      style={{width: "100%", zIndex: 10, textAlign: "center", position: "relative"}}>
                    <JackInTheBox triggerOnce={true} style={{
                        maxWidth: 300,
                        minWidth: 250,
                        width: "100%",
                    }} cascade damping={0.1}>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={math} alt=""/>
                            <p><IntlMessage id={'math'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={rus} alt=""/>
                            <p><IntlMessage id={'rus'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={eng} alt=""/>
                            <p><IntlMessage id={'eng'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%", backgroundColor: clrs.white, color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={physics} alt=""/>
                            <p><IntlMessage id={'physics'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={literature} alt=""/>
                            <p><IntlMessage id={'literature'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={france} alt=""/>
                            <p><IntlMessage id={'france'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={chemistry} alt=""/>
                            <p><IntlMessage id={'chemistry'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={kaz} alt=""/>
                            <p><IntlMessage id={'kaz'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={german} alt=""/>
                            <p><IntlMessage id={'german'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={geography} alt=""/>
                            <p><IntlMessage id={'geography'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={prava} alt=""/>
                            <p><IntlMessage id={'prava'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={informatika} alt=""/>
                            <p><IntlMessage id={'informatika'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={biology} alt=""/>
                            <p><IntlMessage id={'biology'}/></p>
                        </Flex>
                        <Flex gap={20} justify={"center"} align={"center"} style={{
                            cursor: "pointer",
                            fontSize: "1.5rem",
                            height: 100,
                            maxWidth: 300,
                            minWidth: 250,
                            padding: "20px",
                            borderRadius: 25,
                            width: "100%",
                            backgroundColor: clrs.white,
                            color: clrs.black
                        }} onClick={() => { navigate('/p/quizzes/pass') }}>
                            <img style={{width: 50}} src={worldHistory} alt=""/>
                            <p><IntlMessage id={'worldHistory'}/></p>
                        </Flex>
                    </JackInTheBox>

                </Flex>
            </Block>

            <Block id={'subscription'} gap={25} justify={"center"} align={"flex-start"}
                   style={{
                       backgroundColor: clrs.white,
                       padding: size.width > 425 ? "25px 50px" : "20px",
                       position: "relative"
                   }} vertical>
                <Fade triggerOnce={true} style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: 35
                }}>
                    <p style={{fontSize: "1.8rem", fontWeight: "bold", textAlign: "center"}}><IntlMessage
                        id={'subscription'}/></p>
                </Fade>

                <Flex justify={size.width > 1024 ? "space-around" : "center"} align={"center"}
                      gap={size.width > 1024 ? 0 : 20} style={{width: "100%"}} vertical={size.width < 1024}>
                    <JackInTheBox style={{width: "100%", maxWidth: 400}} triggerOnce={true} cascade damping={0.1}>
                        <Flex style={{
                            cursor: "pointer",
                            width: "100%",
                            maxWidth: 400,
                            padding: "60px 30px",
                            backgroundColor: clrs.blue,
                            borderRadius: 25
                        }} vertical align={"center"} justify={"center"} gap={25} onClick={() => {navigate('/p/subscription')}}>
                            <Flex align={"center"} justify={"center"} vertical>
                                <p style={{
                                    fontSize: size.width > 425 ? "5rem" : "4rem",
                                    fontWeight: "bold",
                                    color: clrs.white
                                }}>1500</p>
                                <p style={{fontSize: "1.8rem", fontWeight: "bold", color: clrs.white}}>
                                    <IntlMessage id={'tenge'}/>
                                </p>
                            </Flex>
                            <HorizontalDivider/>
                            <p style={{fontSize: "1.8rem", fontWeight: "bold", color: clrs.white}}>
                                <IntlMessage id={'30days'}/>
                            </p>
                        </Flex>
                    </JackInTheBox>

                    <JackInTheBox style={{width: "100%", maxWidth: 400}} triggerOnce={true} cascade damping={0.1}>
                        <Flex style={{
                            cursor: "pointer",
                            width: "100%",
                            maxWidth: 400,
                            padding: "100px 30px",
                            backgroundColor: clrs.red,
                            position: "relative",
                            borderRadius: 25
                        }} vertical align={"center"} justify={"center"} gap={25} onClick={() => {navigate('/p/subscription')}}>
                            <img className={classes.popover} src={popoverImg} alt=""/>
                            <p className={classes.popoverText}><IntlMessage id={'hit'}/></p>
                            <Flex align={"center"} justify={"center"} vertical>
                                <p style={{
                                    fontSize: size.width > 425 ? "5rem" : "4rem",
                                    fontWeight: "bold",
                                    color: clrs.white
                                }}>7000</p>
                                <p style={{fontSize: "1.8rem", fontWeight: "bold", color: clrs.white}}>
                                    <IntlMessage id={'tenge'}/>
                                </p>
                            </Flex>
                            <HorizontalDivider/>
                            <p style={{fontSize: "1.8rem", fontWeight: "bold", color: clrs.white}}>
                                <IntlMessage id={'180days'}/>
                            </p>
                        </Flex>
                    </JackInTheBox>

                    <JackInTheBox style={{width: "100%", maxWidth: 400}} triggerOnce={true} cascade damping={0.1}>
                        <Flex style={{
                            cursor: "pointer",
                            width: "100%",
                            maxWidth: 400,
                            padding: "60px 30px",
                            backgroundColor: clrs.green,
                            borderRadius: 25
                        }} vertical align={"center"} justify={"center"} gap={25} onClick={() => {navigate('/p/subscription')}}>
                            <Flex align={"center"} justify={"center"} vertical>
                                <p style={{
                                    fontSize: size.width > 425 ? "5rem" : "4rem",
                                    fontWeight: "bold",
                                    color: clrs.white
                                }}>4000</p>
                                <p style={{fontSize: "1.8rem", fontWeight: "bold", color: clrs.white}}>
                                    <IntlMessage id={'tenge'}/>
                                </p>
                            </Flex>
                            <HorizontalDivider/>
                            <p style={{fontSize: "1.8rem", fontWeight: "bold", color: clrs.white}}>
                                <IntlMessage id={'90days'}/>
                            </p>
                        </Flex>
                    </JackInTheBox>
                </Flex>


            </Block>

            <Footer/>
        </Flex>
    );
};

export default HomePage;