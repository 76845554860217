import React, {useState} from 'react';
import {Drawer, Flex} from "antd";
import classes from './header.module.css';
import burger from '../../../images/burger-menu-left.svg';
import RedButton from "../../../components/UI/Buttons/RedButton";
import IntlMessage from "../../../components/IntlMessage/IntlMessage";
import {clrs} from "../../../constants/colors";
import {useWindowSize} from "@uidotdev/usehooks";
import {Link, useNavigate} from "react-router-dom";
import {useScroll} from "../../../components/Scroll/Scroll";
import BrainBrewText from "../../../components/BrainBrew/BrainBrewText";
import HorizontalDivider from "../../../components/Divider/HorizontalDivider";
import DefaultButton from "../../../components/UI/Buttons/DefaultButton";
import HoverButton from "../../../components/UI/Buttons/HoverButton";
const Header = () => {
    const size = useWindowSize();
    const scroll = useScroll();
    const navigate = useNavigate();

    const [openNav, setOpenNav] = useState(false);

    return (
        <Flex className={classes.header}>
            <Flex onClick={() => {setOpenNav(true)}} style={{width: 30, height: 30, cursor: "pointer"}}>
                <img src={burger} alt=""/>
            </Flex>
            {
                size.width > 1024 &&
                <>
                    <Flex gap={25}>
                        <Link to={'/aboutUs'} className={classes.menu_item}>
                            <IntlMessage id={'aboutUs'}/>
                        </Link>
                        <Link to={'/'} onClick={() => {
                            navigate('/');
                            scroll('ent');
                        }} className={classes.menu_item}>
                            <IntlMessage id={'ENT'}/>
                        </Link>
                        <Link to={''} className={classes.menu_item}>
                            <IntlMessage id={'resources'}/>
                        </Link>
                        <Link to={'/'} onClick={() => {
                            navigate('/');
                            scroll('subscription');
                        }} className={classes.menu_item}>
                            <IntlMessage id={'subscription'}/>
                        </Link>
                        <Link to={'/contacts'} className={classes.menu_item}>
                            <IntlMessage id={'contacts'}/>
                        </Link>
                    </Flex>
                    <RedButton onClick={() => {navigate('/register')}}>
                        <IntlMessage id={'home.passTestENT'}/>
                    </RedButton>
                </>
            }
            {
                size.width > 425 &&
                <Flex gap={35} align={"center"} justify={"center"}>
                    <Link to={'/register'} className={classes.menu_item}>
                        <IntlMessage id={'registrationSmall'}/>
                    </Link>
                    <Link to={'/login'} className={classes.loginBtn} style={{fontWeight: "bold", cursor: "pointer", fontSize: "1rem"}}>
                        <IntlMessage id={'loginSmall'}/>
                    </Link>
                </Flex>
            }


            <Drawer
                title={<BrainBrewText/>}
                width={325}
                placement={"left"}
                onClose={() => {setOpenNav(false)}}
                open={openNav}
            >
                <Flex vertical gap={25}>
                    <Link to={'/aboutUs'} className={classes.menu_item}>
                        <IntlMessage id={'aboutUs'}/>
                    </Link>
                    <Link to={'/'} onClick={() => {
                        navigate('/');
                        scroll('ent');
                    }} className={classes.menu_item}>
                        <IntlMessage id={'ENT'}/>
                    </Link>
                    <Link to={''} className={classes.menu_item}>
                        <IntlMessage id={'resources'}/>
                    </Link>
                    <Link to={'/'} onClick={() => {
                        navigate('/');
                        scroll('subscription');
                    }} className={classes.menu_item}>
                        <IntlMessage id={'subscription'}/>
                    </Link>
                    <Link to={'/contacts'} className={classes.menu_item}>
                        <IntlMessage id={'contacts'}/>
                    </Link>
                    <RedButton onClick={() => {navigate('/register')}}>
                        <IntlMessage id={'home.passTestENT'}/>
                    </RedButton>
                    <HorizontalDivider/>
                    <HoverButton onClick={() => {navigate('/register')}}>
                        <IntlMessage id={'registrationSmall'}/>
                    </HoverButton>
                    <RedButton onClick={() => {navigate('/login')}}>
                        <IntlMessage id={'loginSmall'}/>
                    </RedButton>
                </Flex>
            </Drawer>
        </Flex>
    );
};

export default Header;