import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg height={35} viewBox="0 0 62.59 79.28">
            <g>
                <g>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m55.72,66.02c1.75-.81,3.59-1.55,5.28-2.48.79.24,1.81-.31,1.53-1.34-5.67-20.93-12.54-41.53-20.73-61.61-.16-.4-.77-.69-1.18-.55C27.2,4.59,13.19,8.47.21,14.2c-.41.18-.16.82.27.73,8.67-2,17.16-5.15,25.61-7.91,2-.65,4-1.31,6-1.96,2.12-.69,4.56-1.99,6.76-2.2,2.19-.21,2.26,1.74,3,3.63.82,2.11,1.62,4.22,2.41,6.33,5.97,15.97,11.16,32.23,15.63,48.68-12.83,4.52-25.54,9.48-38.2,14.46C14.01,55.94,7.12,35.64,1.06,15.07c-.07-.23-.43-.15-.37.08,5.74,20.96,12.28,41.69,19.62,62.14.15.41.56.85,1.06.67.32-.11.64-.23.97-.35.56.12,1.11.26,1.65.48.83.34,1.71,1.21,2.62,1.18.19,0,.4-.18.33-.4-.04-.11-.09-.22-.15-.31,11.43-2.4,23.67-5.13,34.36-9.89.29-.13.11-.6-.19-.52-5.79,1.41-11.42,3.57-17.14,5.23-5.74,1.67-11.53,3.16-17.32,4.66-.05.01-.08.03-.11.06-.53-.45-1.3-.71-1.9-.94-.16-.06-.32-.11-.48-.16,10.4-3.71,20.79-7.47,31.09-11.46-.04.29.26.62.61.46Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m61.92,65.48c-1.06.24-2.11.52-3.16.81.05-.02.1-.05.15-.07.18-.09.08-.41-.13-.35-.68.19-1.35.41-2.03.61.3-.11.59-.21.89-.32.29-.11.21-.56-.11-.49-7.53,1.79-14.88,5.03-21.85,8.35-3.14,1.18-6.3,2.32-9.48,3.35-.15.05-.1.29.05.24,9.06-2.67,18.64-5.2,27.45-8.97,2.77-.92,5.55-1.79,8.33-2.72.28-.09.2-.52-.1-.46Z"/>
                    <path strokeWidth={0} fill={"currentColor"}
                          d="m41.62,21.8c-.98-1.63-1.84-3.34-2.48-5.14-.49-1.39-.58-3.13-1.46-4.31-.13-.43-.6-.81-1.15-.58-.02,0-.03.01-.05.02-.17-.16-.51-.08-.53.2,0,0,0,.01,0,.02-8.64,3.58-17.27,7.19-25.83,10.98-.04-.03-.08-.07-.12-.09-.3-.19-.7.01-.72.37-.08,1.47,1,2.91,1.59,4.22.75,1.69,1.42,3.42,2.17,5.11.16.37.53.48.88.43.11.22.22.44.33.66.23.45.76.56,1.2.38,4.24-1.77,8.43-3.65,12.69-5.35,4.24-1.69,8.71-2.95,12.85-4.87.4-.19.59-.67.5-1.1.24-.23.34-.58.12-.95Zm-26.29,10.33c-.67-1.02-1.28-2.07-1.87-3.11-.3-.68-.61-1.35-.93-2.02-.53-1.11-1.01-2.58-1.87-3.53,8.48-3.13,16.9-6.41,25.31-9.74.21,3.13,1.47,5.87,3.04,8.59-8.08,2.04-16.34,5.96-23.68,9.81Z"/>
                </g>
            </g>
        </svg>
    );
};

const PravaIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default PravaIcon;