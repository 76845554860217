import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg height={35} viewBox="0 0 74.1 59.88">
            <g>
                <g>
                    <path stroke={"currentColor"} fill={"none"}
                          d="m9.81,2.66h46.85c1.44,0,2.62,1.17,2.62,2.62v36.2H7.2V5.27c0-1.44,1.17-2.62,2.62-2.62Z"
                          transform="translate(-1.95 3.27) rotate(-5.47)"/>
                    <g>
                        <g>
                            <g>
                                <line stroke={"currentColor"} fill={"none"} x1="41.52" y1="2.06" x2="47.99" y2="-1.3"/>
                                <line stroke={"currentColor"} fill={"none"} x1="42.34" y1="3.22" x2="48.79" y2="-.19"/>
                                <line stroke={"currentColor"} fill={"none"} x1="43.41" y1="4.39" x2="49.85" y2=".98"/>
                                <line stroke={"currentColor"} fill={"none"} x1="44.1" y1="5.81" x2="50.55" y2="2.4"/>
                                <line stroke={"currentColor"} fill={"none"} x1="44.84" y1="6.95" x2="51.29" y2="3.54"/>
                                <line stroke={"currentColor"} fill={"none"} x1="46.83" y1="9.56" x2="53.27" y2="6.14"/>
                                <line stroke={"currentColor"} fill={"none"} x1="45.95" y1="8.15" x2="52.4" y2="4.74"/>
                                <g>
                                    <line stroke={"currentColor"} fill={"none"} x1="48.03" y1="10.77" x2="54.51" y2="7.42"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="48.86" y1="11.94" x2="55.31" y2="8.53"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="49.92" y1="13.11" x2="56.37" y2="9.7"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="50.62" y1="14.53" x2="57.06" y2="11.12"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="51.36" y1="15.67" x2="57.8" y2="12.26"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="53.34" y1="18.28" x2="59.79" y2="14.86"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="52.47" y1="16.87" x2="58.92" y2="13.46"/>
                                </g>
                            </g>
                            <g>
                                <line stroke={"currentColor"} fill={"none"} x1="4.74" y1="27.93" x2="11.22" y2="24.57"/>
                                <line stroke={"currentColor"} fill={"none"} x1="5.57" y1="29.09" x2="12.02" y2="25.68"/>
                                <line stroke={"currentColor"} fill={"none"} x1="6.63" y1="30.27" x2="13.08" y2="26.86"/>
                                <line stroke={"currentColor"} fill={"none"} x1="7.33" y1="31.68" x2="13.77" y2="28.27"/>
                                <line stroke={"currentColor"} fill={"none"} x1="8.07" y1="32.82" x2="14.51" y2="29.41"/>
                                <line stroke={"currentColor"} fill={"none"} x1="10.05" y1="35.43" x2="16.5" y2="32.02"/>
                                <line stroke={"currentColor"} fill={"none"} x1="9.18" y1="34.02" x2="15.63" y2="30.61"/>
                                <g>
                                    <line stroke={"currentColor"} fill={"none"} x1="11.26" y1="36.65" x2="17.74" y2="33.29"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="12.09" y1="37.81" x2="18.53" y2="34.4"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="13.15" y1="38.99" x2="19.6" y2="35.57"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="13.84" y1="40.4" x2="20.29" y2="36.99"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="14.58" y1="41.54" x2="21.03" y2="38.13"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="16.57" y1="44.15" x2="23.02" y2="40.74"/>
                                    <line stroke={"currentColor"} fill={"none"} x1="15.7" y1="42.74" x2="22.14" y2="39.33"/>
                                </g>
                            </g>
                        </g>
                        <rect stroke={"currentColor"} fill={"none"} x="11.58" y="5.92" width="43.32" height="32.29"
                              transform="translate(-1.95 3.27) rotate(-5.47)"/>
                    </g>
                    <path stroke={"currentColor"} fill={"none"}
                          d="m9.17,43.87L.78,56.77c-.77,1.19.17,2.75,1.58,2.61l69.71-6.68c1.47-.14,2.07-1.96.97-2.94l-12.03-10.85"/>
                    <polyline stroke={"currentColor"} fill={"none"} points="12.1 43.59 5.73 55.69 68.39 49.69 57.94 39.2"/>
                    <line stroke={"currentColor"} fill={"none"} x1="7.26" y1="52.78" x2="65.88" y2="47.17"/>
                    <line stroke={"currentColor"} fill={"none"} x1="8.91" y1="49.64" x2="63.17" y2="44.44"/>
                    <line stroke={"currentColor"} fill={"none"} x1="11.18" y1="46.32" x2="60.35" y2="41.61"/>
                    <line stroke={"currentColor"} fill={"none"} x1="18.25" y1="43" x2="14.96" y2="54.46"/>
                    <line stroke={"currentColor"} fill={"none"} x1="23.71" y1="42.97" x2="22.57" y2="53.91"/>
                    <line stroke={"currentColor"} fill={"none"} x1="28.91" y1="42.48" x2="29.31" y2="53.26"/>
                    <line stroke={"currentColor"} fill={"none"} x1="34.21" y1="41.47" x2="35.87" y2="52.81"/>
                    <line stroke={"currentColor"} fill={"none"} x1="53.29" y1="39.89" x2="58.7" y2="50.52"/>
                    <line stroke={"currentColor"} fill={"none"} x1="48.74" y1="40.34" x2="53.06" y2="50.75"/>
                    <line stroke={"currentColor"} fill={"none"} x1="43.8" y1="40.81" x2="47.33" y2="51.62"/>
                    <line stroke={"currentColor"} fill={"none"} x1="38.67" y1="41.2" x2="41.44" y2="52.1"/>
                </g>
            </g>
        </svg>
    );
};

const InformatikaIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default InformatikaIcon;