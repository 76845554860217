import React from 'react';
import { Button } from 'antd';
import classes from './buttons.module.css';

const BhargaButton = (props) => {
    return (
        <Button {...props} className={classes.bhargaBtn}>
            <span>{props.children}</span>
        </Button>
    );
};

BhargaButton.propTypes = {
    ...Button.propTypes, // Use Ant Design Button propTypes
};

export default BhargaButton;