import React, {useEffect} from 'react';
import classes from './PlatformNav.module.css';
import NavItem from "./NavItem";
import HorizontalDivider from "../../../../components/Divider/HorizontalDivider";
import NavLan from "./NavLan";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {useDispatch, useSelector} from "react-redux";
import {
    FileTextOutlined,
    HomeOutlined, LineChartOutlined,
    LogoutOutlined,
    ProfileFilled,
    ProfileOutlined, ReadOutlined, SettingOutlined, TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useLocation} from "react-router-dom";
import SubscriptionIcon from "../../../../components/icons/SubscriptionIcon";
import ComplaintIcon from "../../../../components/icons/ComplaintIcon";
import BrainBrewText from "../../../../components/BrainBrew/BrainBrewText";

const NavMenu = () => {
    const {user} = useSelector(state => state.user);

    return (
        <div className={classes.menu}>
            {/*<NavLan/>*/}
            <BrainBrewText/>
            <div style={{paddingBottom: 25}}></div>
            <HorizontalDivider/>
            <NavItem to={'home'} loc={'/p/home'} icon={<HomeOutlined />} text={<IntlMessage id={'personalCabinet'}/>}/>
            <NavItem to={'profile'} loc={'/p/profile'} icon={<UserOutlined />} text={<IntlMessage id={'profile'}/>}/>
            <NavItem to={'subscription'} loc={'/p/subscription'} icon={<SubscriptionIcon />} text={<IntlMessage id={'subscription'}/>}/>

            {/*<NavItem to={'stats'} loc={'/p/stats'} icon={<LineChartOutlined />} text={<IntlMessage id={'stats'}/>}/>*/}
            <NavItem to={'quizzes'} loc={'/p/quizzes'} icon={<ProfileOutlined />} text={<IntlMessage id={'myQuizzes'}/>}/>
            {/*<RoleNav/>*/}
            <NavItem to={'statement'} loc={'/p/statement'} icon={<ComplaintIcon />} text={<IntlMessage id={'complaints-suggestions'}/>}/>
            <NavItem to={'settings/profile'} loc={'/p/settings/profile'} icon={<SettingOutlined />} text={<IntlMessage id={'settings'}/>}/>
            <HorizontalDivider/>
            {
                user &&
                <NavItem to={'logout'} icon={<LogoutOutlined />} text={<IntlMessage id={'logout'}/>}/>
            }
        </div>
    );
};

export default NavMenu;

