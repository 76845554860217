import React, {useState} from 'react';
import {Breadcrumb, Flex, Image} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {useWindowSize} from "@uidotdev/usehooks";
import {Link, useNavigate} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import {API_BASE_URL} from "../../../../constants/api";
import profile_user from '../../../../images/profile_user.jpg';
import {useSelector} from "react-redux";
import {clrs} from "../../../../constants/colors";
import HorizontalDivider from "../../../../components/Divider/HorizontalDivider";
import BhargaButton from "../../../../components/UI/Buttons/BhargaButton";
import SteveBloxButton from "../../../../components/UI/Buttons/SteveBloxButton";
const ProfilePage = () => {
    const size = useWindowSize();

    const {user} = useSelector(state => state.user);

    const navigate = useNavigate();

    const breadItems = [
        {
            title: <Link to={'/p/home'}><HomeOutlined /></Link>,
        },
        {
            title: <IntlMessage id={'profile'}/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <MyText uppercase strong size={"large"}>
                <IntlMessage id={'profile'}/>
            </MyText>
            <Breadcrumb
                items={breadItems}
            />
            <Flex gap={50} wrap={"wrap"} align={"flex-start"} justify={"center"}>
                <Image
                    preview={false}
                    style={{maxWidth: 250, width: "100%", borderRadius: 8}}
                    src={profile_user}
                />
                <Flex vertical style={{maxWidth: "100%"}} gap={25}>
                    <MyText style={{color: clrs.blacker2}} strong size={"large"}>
                        {user.personalData?.fullName}
                    </MyText>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'username'}/>
                        </MyText>
                        <MyText>
                            {user.username}
                        </MyText>
                    </Flex>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'telNumber'}/>
                        </MyText>
                        <MyText>
                            {user.personalData?.telNumber}
                        </MyText>
                    </Flex>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'email'}/>
                        </MyText>
                        <MyText>
                            {user.personalData?.email}
                        </MyText>
                    </Flex>
                    <HorizontalDivider/>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'profile.gender'}/>
                        </MyText>
                        <MyText>
                            {user.personalData?.gender === true && <IntlMessage id={'settings.gender.true'}/>}
                            {user.personalData?.gender === false && <IntlMessage id={'settings.gender.false'}/>}
                        </MyText>
                    </Flex>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'profile.regionRegistered'}/>
                        </MyText>
                        <MyText>
                            {user.personalData?.regionRegistered}
                        </MyText>
                    </Flex>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'profile.townRegistered'}/>
                        </MyText>
                        <MyText>
                            {user.personalData?.townRegistered}
                        </MyText>
                    </Flex>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'profile.schoolName'}/>
                        </MyText>
                        <MyText>
                            {user.personalData?.schoolName}
                        </MyText>
                    </Flex>
                    <Flex gap={25} wrap={"wrap"}>
                        <MyText strong style={{width: "200px", color: clrs.blacker2}}>
                            <IntlMessage id={'profile.studentClass'}/>
                        </MyText>
                        <MyText>
                            {user.personalData?.studentClass === 'CLASS_5' && '5'}
                            {user.personalData?.studentClass === 'CLASS_6' && '6'}
                            {user.personalData?.studentClass === 'CLASS_7' && '7'}
                            {user.personalData?.studentClass === 'CLASS_8' && '8'}
                            {user.personalData?.studentClass === 'CLASS_9' && '9'}
                            {user.personalData?.studentClass === 'CLASS_10' && '10'}
                            {user.personalData?.studentClass === 'CLASS_11' && '11'}
                            {user.personalData?.studentClass === 'CLASS_11' && '11'}
                        </MyText>
                    </Flex>
                    <SteveBloxButton onClick={() => {navigate('/p/settings/profile')}}>Редактировать</SteveBloxButton>
                </Flex>
            </Flex>

        </Flex>
    );
};

export default ProfilePage;