import React from 'react';
import BrainBrewText from "../../components/BrainBrew/BrainBrewText";
import Block from "../components/Block/Block";
import {clrs} from "../../constants/colors";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import {Flex, Form, Input, message} from "antd";
import classes from "../global_styles.module.css";
import FormItem from "antd/lib/form/FormItem";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import RedButton from "../../components/UI/Buttons/RedButton";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useWindowSize} from "@uidotdev/usehooks";
import AuthService from "../../services/AuthService";
import {getUserByToken} from "../../store/slices/userSlice";
import PhoneIcon from "../../components/icons/PhoneIcon";
import UserService from "../../services/UserService";

const ForgotPasswordPage = () => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const username = Form.useWatch('username', form);
    const telNumber = Form.useWatch('telNumber', form);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const size = useWindowSize();

    function handleOnSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                await UserService.sendChangePasswordMessage(username, telNumber)
                    .then(async (result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'forgot-password.sendSuccessText'}/>, 5);
                        navigate('/login');
                    })
                    .catch((result) => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
            })
            .catch(() => {

            })
    }

    return (
        <Flex vertical gap={50} align={"center"} justify={"center"} style={{width: "100%", minHeight: "100vh", padding: size.width > 768 ? 100 : 20}}>
            <BrainBrewText/>
            <Block gap={25} vertical style={{backgroundColor: clrs.yellow, maxWidth: 700, width: "100%", padding: size.width > 768 ? "25px 50px" : "25px 20px"}}>
                <p style={{color: clrs.white, fontWeight: "bold"}}><IntlMessage id={'forgot-password'}/></p>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} vertical gap={15}>
                    <Form form={form} style={{maxWidth: 500}} className={classes.form}>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="username">
                            <Input placeholder={t('username')} prefix={<UserOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="telNumber">
                            <Input placeholder={t('telNumber')} prefix={<PhoneIcon style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <RedButton onClick={handleOnSubmit}>
                            <IntlMessage id={'send'}/>
                        </RedButton>
                    </Form>
                    <Flex vertical={size.width < 500} style={{width: "100%", maxWidth: 500}} gap={20} align={"center"} justify={"space-between"}>
                        <Link to={'/login'} style={{color: clrs.blacker2, fontWeight: "bold"}}>
                            <IntlMessage id={'login'}/>
                        </Link>
                    </Flex>
                </Flex>
            </Block>

        </Flex>
    );
};

export default ForgotPasswordPage;