import React, {useEffect, useState} from 'react';
import BrainBrewText from "../../components/BrainBrew/BrainBrewText";
import Block from "../components/Block/Block";
import {clrs} from "../../constants/colors";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import {Flex, Form, Input, message} from "antd";
import classes from "../global_styles.module.css";
import FormItem from "antd/lib/form/FormItem";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import PhoneIcon from "../../components/icons/PhoneIcon";
import RedButton from "../../components/UI/Buttons/RedButton";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useWindowSize} from "@uidotdev/usehooks";
import UserService from "../../services/UserService";
import {PasswordValidator} from "../../utils/PasswordValidator";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";

const ChangePasswordPage = () => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const size = useWindowSize();

    const {token} = useParams();

    useEffect(() => {
        if (token) {
            message.loading(<IntlMessage id={'loading'}/>, 0);
            UserService.checkChangePasswordToken(token)
                .then(async (result) => {
                    message.destroy();
                    setLoading(false);
                })
                .catch((result) => {
                    message.destroy();
                    message.error(<IntlMessage id={'error'}/>, 5);
                    navigate('/login');
                })
        } else {
            message.error(<IntlMessage id={'error'}/>, 5);
            navigate('/login')
        }
    }, []);

    function handleOnSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                await UserService.changePasswordOfUser(password, token)
                    .then(async (result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        navigate('/login');
                    })
                    .catch((result) => {
                        message.destroy();
                        if (result.response.data === 'password is not valid') {
                            message.error(<IntlMessage id={'resetPassword.invalidPassword'}/>, 5);
                        } else {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        }
                    })
            })
            .catch(() => {

            })
    }

    return (
        <Flex vertical gap={50} align={"center"} justify={"center"} style={{width: "100%", minHeight: "100vh", padding: size.width > 768 ? 100 : 20}}>
            <BrainBrewText/>
            <Block gap={25} vertical style={{backgroundColor: clrs.yellow, position: "relative", maxWidth: 700, width: "100%", padding: size.width > 768 ? "25px 50px" : "25px 20px"}}>

                <BlockLoading isLoading={loading}/>
                <p style={{color: clrs.white, fontWeight: "bold"}}><IntlMessage id={'resetPassword'}/></p>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"} vertical gap={15}>
                    <Form form={form} style={{maxWidth: 500}} className={classes.form}>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (PasswordValidator.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('passwordRules')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="password">
                            <Input.Password placeholder={t('password')} prefix={<KeyOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('confirmPasswordError')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="confirmPassword">
                            <Input.Password placeholder={t('confirmPassword')} prefix={<KeyOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput}/>
                        </FormItem>
                        <RedButton onClick={handleOnSubmit}>
                            <IntlMessage id={'resetPassword'}/>
                        </RedButton>
                    </Form>
                    <Flex vertical={size.width < 500} style={{width: "100%", maxWidth: 500}} gap={20} align={"center"} justify={"space-between"}>
                        <Link to={'/login'} style={{color: clrs.blacker2, fontWeight: "bold"}}>
                            <IntlMessage id={'login'}/>
                        </Link>
                    </Flex>
                </Flex>
            </Block>

        </Flex>
    );
};

export default ChangePasswordPage;