import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const StatsService = {};

StatsService.getStats = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/user/stats/get",
        params: {
            token: localStorage.getItem(ACCESS_TOKEN)
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default StatsService;