import React from 'react';
import {Flex} from "antd";
import {clrs} from "../../constants/colors";
import classes from './brainbrew.module.css';
import {Link} from "react-router-dom";

const BrainBrewText = () => {

    return (
        <Link to={'/'}>
            <Flex style={{maxWidth: 300, width: "100%", fontSize: "2.2rem", paddingTop: 10, cursor: "pointer"}} align={"center"} justify={"center"}>
                <span className={classes.text} style={{color: clrs.black, fontWeight: 700}}>BRAIN</span><span className={classes.text} style={{color: clrs.red, fontWeight: 700}}>BREW</span>
            </Flex>
        </Link>
    );
};

export default BrainBrewText;