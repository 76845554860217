import React, {useEffect, useState} from 'react';
import MyText from "../../../../components/UI/Text/MyText";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {Alert, Breadcrumb, Button, Card, Empty, Flex, Image, List, message, Modal, Table, Tag, Upload} from "antd";
import {Link} from "react-router-dom";
import {HomeOutlined, InboxOutlined, UploadOutlined} from "@ant-design/icons";
import {clrs} from "../../../../constants/colors";
import BhargaButton from "../../../../components/UI/Buttons/BhargaButton";
import SteveBloxButton from "../../../../components/UI/Buttons/SteveBloxButton";
import HorizontalDivider from "../../../../components/Divider/HorizontalDivider";
import {useWindowSize} from "@uidotdev/usehooks";
import blueWave from '../../../../images/blue_wave.svg';
import greenWave from '../../../../images/green_wave.svg';
import redWave from '../../../../images/red_wave.svg';
import BrainBrewKaspiQR from '../../../../images/BrainBrew_KaspiQR.jpg';
import classes from './subscription.module.css';
import DoubleCheckIcon from "../../../../components/icons/DoubleCheckIcon";
import {useDispatch, useSelector} from "react-redux";
import DotsLoader from "../../../../components/LoadingComponents/Loaders/DotsLoader";
import NorthButton from "../../../../components/UI/Buttons/NorthButton";
import {LocalName} from "../../../../utils/LocalName";
import QuizService from "../../../../services/QuizService";
import {clearUser, setUserState} from "../../../../store/slices/userSlice";
import SubscriptionService from "../../../../services/SubscriptionService";
import Dragger from "antd/lib/upload/Dragger";
import BlockLoading from "../../../../components/LoadingComponents/BlockLoading";

const SubscriptionPage = () => {

    const size = useWindowSize();

    const [openModal, setOpenModal] = useState(false);
    const [buyLoading, setBuyLoading] = useState(false);

    const dispatch = useDispatch();

    const [selectedSub, setSelectedSub] = useState(0);

    const {subscription} = useSelector(state => state.user.user);

    const [initLoading, setInitLoading] = useState(true);
    const [transactionList, setTransactionList] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const [fileList, setFileList] = useState([]);

    const breadItems = [
        {
            title: <Link to={'/p/home'}><HomeOutlined /></Link>,
        },
        {
            title: <IntlMessage id={'subscription'}/>,
        },
    ]

    useEffect(() => {
        SubscriptionService.getMyTransactions(currentPage, pageSize)
            .then((result) => {
                console.log(result)
                setTransactionList(result.data);
                setHasMore(result.hasMore);
                setInitLoading(false);
            })
            .catch((result) => {
                console.log(result)
                setInitLoading(false);
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }, []);

    const handlePaginationChange = (page, pageSize) => {
        setInitLoading(true);
        setCurrentPage(page);
        setPageSize(pageSize);
        SubscriptionService.getMyTransactions(page, pageSize)
            .then((result) => {
                setTransactionList(result.data);
                setHasMore(result.hasMore);
                setInitLoading(false);
            })
            .catch((result) => {
                setInitLoading(false);
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    const props = {
        name: 'file',
        beforeUpload: (info) => {return false},
        multiple: false,
        onChange(info) {
            console.log(info.file);
            if (info.file?.type === "application/pdf") {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setBuyLoading(true);

                const subscriptionType = selectedSub === 1 ? "DAYS_30" : selectedSub === 2 ? "DAYS_90" : selectedSub === 3 ? "DAYS_180" : null;
                handleBuy(info.file, subscriptionType);
            } else {
                message.error(<IntlMessage id={'subscription.invalidFormatFile'}/>, 0);
            }
        },
    };

    async function handleBuy(file, subscriptionType) {
        await SubscriptionService.uploadAccount(file, subscriptionType)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setBuyLoading(false);
                dispatch(setUserState(r.data));
                setOpenModal(false);
                SubscriptionService.getMyTransactions(currentPage, pageSize)
                    .then((result) => {
                        console.log(result)
                        setTransactionList(result.data);
                        setHasMore(result.hasMore);
                        setInitLoading(false);
                    })
                    .catch((result) => {
                        console.log(result)
                        setInitLoading(false);
                        message.error(<IntlMessage id={'error'}/>, 5);
                        if (result.response.status === 401) {
                            message.destroy();
                            localStorage.removeItem('Authorization');
                            dispatch(clearUser())
                            message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                        }
                    })
            })
            .catch((result) => {
                message.destroy();
                setBuyLoading(false);
                if (result.response.data === 'email is not confirmed') {
                    message.error(<IntlMessage id={'emailNotConfirmedError'}/>, 5);
                } else {
                    message.error(<IntlMessage id={'error'}/>, 5);
                }
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <MyText uppercase strong size={"large"}>
                <IntlMessage id={'subscription'}/>
            </MyText>
            <Breadcrumb
                items={breadItems}
            />
            <Flex style={{width: "100%"}}>
                <Alert type={"warning"} style={{width: "100%"}} message={<strong><IntlMessage id={'subscription.warning'}/></strong>} description={<IntlMessage id={'subscription.warning.text'}/>}/>
            </Flex>

            <Flex gap={25} style={{width: "100%"}} vertical={size.width < 1200} align={"flex-start"} justify={"center"}>
                {subscription.isValid ?
                    <Card style={{color: clrs.white, height: size.width < 1200 ? 275 : 400, width: size.width < 1200 ? "100%" : 400, textAlign: "center", backgroundColor: clrs.yellow, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Flex align={"center"} justify={"center"} vertical gap={20}>
                            {/*<p style={{fontSize: "1.4rem", fontWeight: 400}}>Отсутствует активная подписка</p>*/}
                            <p style={{fontSize: size.width > 768 ? "1.4rem" : "1.2rem", fontWeight: 500}}><IntlMessage id={'subscription.currentSubscription'}/></p>
                            <p style={{fontSize: "1rem", fontWeight: 500}}>до</p>
                            <p style={{fontSize: size.width > 768 ? "4rem" : "3rem", fontWeight: "bold"}}>{new Date(subscription.endedAt).toLocaleDateString()}</p>
                        </Flex>
                    </Card>
                    :
                    <Card style={{color: clrs.white, height: size.width < 1200 ? 275 : 400, width: size.width < 1200 ? "100%" : 400, textAlign: "center", backgroundColor: clrs.blacker2, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Flex align={"center"} justify={"center"} vertical gap={20}>
                            {/*<p style={{fontSize: "1.4rem", fontWeight: 400}}>Отсутствует активная подписка</p>*/}
                            <p style={{fontSize: size.width > 768 ? "1.4rem" : "1.2rem", fontWeight: 400}}><IntlMessage id={'subscription.currentSubscription'}/></p>
                            {/*<p style={{fontSize: "1rem", fontWeight: 500}}>до</p>*/}
                            <p style={{fontSize: "1.6rem", fontWeight: "bold"}}><IntlMessage id={'subscription.noSubscription'}/></p>
                        </Flex>
                    </Card>
                }




                <Card style={{maxWidth: "100%", position: "relative", width: size.width < 1200 ? "100%" : "calc(100% - 25px - 400px)", height: 400}}>
                    <Flex align={"center"} gap={10} style={{width: "100%", height: "100%"}} justify={"space-between"} vertical>
                        <p style={{fontSize: "1.4rem"}}>Подписки</p>
                        <Card onClick={() => {setSelectedSub(1)}} className={classes.subscriptionCard} style={{border: `1px solid ${clrs.blue}`}}>
                            <div className={classes.waveBack} style={{backgroundColor: clrs.blue}}></div>
                            <img src={blueWave} className={classes.wave} alt=""/>
                            <p className={classes.daysText}><IntlMessage id={'subscription.30days'}/></p>
                            <p className={classes.priceText}>1500 <IntlMessage id={'subscription.tenge'}/></p>
                            <div className={classes.monthPriceBlock} style={{backgroundColor: clrs.blue}}></div>
                            <p className={classes.monthPriceText}>50 <IntlMessage id={'subscription.tenge'}/> / <br/><IntlMessage id={'subscription.perDay'}/></p>
                            {selectedSub === 1 && <DoubleCheckIcon className={classes.doubleCheck}/>}
                        </Card>

                        <Card onClick={() => {setSelectedSub(2)}} className={classes.subscriptionCard} style={{border: `1px solid ${clrs.green}`}}>
                            <div className={classes.waveBack} style={{backgroundColor: clrs.green}}></div>
                            <img src={greenWave} className={classes.wave} alt=""/>
                            <p className={classes.daysText}><IntlMessage id={'subscription.90days'}/></p>
                            <p className={classes.priceText}>4000 <IntlMessage id={'subscription.tenge'}/></p>
                            <div className={classes.monthPriceBlock} style={{backgroundColor: clrs.green}}></div>
                            <p className={classes.monthPriceText}>44 <IntlMessage id={'subscription.tenge'}/> / <br/><IntlMessage id={'subscription.perDay'}/></p>
                            {selectedSub === 2 && <DoubleCheckIcon className={classes.doubleCheck}/>}
                        </Card>

                        <Card onClick={() => {setSelectedSub(3)}} className={classes.subscriptionCard} style={{border: `1px solid ${clrs.red}`}}>
                            <div className={classes.waveBack} style={{backgroundColor: clrs.red}}></div>
                            <img src={redWave} className={classes.wave} alt=""/>
                            <p className={classes.daysText}><IntlMessage id={'subscription.180days'}/></p>
                            <p className={classes.priceText}>7000 <IntlMessage id={'subscription.tenge'}/></p>
                            <div className={classes.monthPriceBlock} style={{backgroundColor: clrs.red}}></div>
                            <p className={classes.monthPriceText}>38 <IntlMessage id={'subscription.tenge'}/> / <br/><IntlMessage id={'subscription.perDay'}/></p>
                            {selectedSub === 3 && <DoubleCheckIcon className={classes.doubleCheck}/>}
                        </Card>
                        <SteveBloxButton onClick={() => {
                            if (selectedSub >= 1 && selectedSub <= 3) {
                                setOpenModal(true);
                            } else {
                                message.error(<IntlMessage id={'subscription.pleaseChoose'}/>, 5);
                            }
                        }}>
                            <IntlMessage id={'buy'}/>
                        </SteveBloxButton>
                    </Flex>
                </Card>
            </Flex>
            <Flex style={{width: "100%"}}>
                <Alert type={"info"} style={{width: "100%"}} message={<strong><IntlMessage id={'subscription.important'}/></strong>} description={<IntlMessage id={'subscription.important.text'}/>}/>
            </Flex>
            <Flex gap={25} style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical>
                <MyText uppercase strong size={"default"}>
                    <IntlMessage id={'subscription.history'}/>
                </MyText>
                <Flex align={"flex-start"} justify={"flex-start"}
                      style={{padding: 20, width: "100%", minHeight: "150px", overflow: "scroll", backgroundColor: "white", position: "relative", borderRadius: 8}} vertical gap={20}>
                    <Flex style={{width: "100%", minWidth: 800, position: "relative"}} gap={15}>
                        <Flex style={{width: "30%"}}>
                            <strong><IntlMessage id={'subscription.createdAt'}/></strong>
                        </Flex>
                        <Flex style={{width: "20%"}}>
                            <strong><IntlMessage id={'subscription.subscriptionType'}/></strong>
                        </Flex>
                        <Flex style={{width: "50%"}}>
                            <strong><IntlMessage id={'subscription.comment'}/></strong>
                        </Flex>
                    </Flex>
                    <List
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<IntlMessage id={'nothingFound'}/>}/> }}
                        style={{width: "100%", minWidth: 800, justifyContent: "center"}}
                        loading={{indicator: <DotsLoader/>, spinning: initLoading}}
                        itemLayout="horizontal"
                        dataSource={transactionList}
                        pagination={{
                            align: size.width > 768 ? "end" : "start",
                            current: currentPage,
                            total: hasMore
                                ? currentPage * pageSize + 1
                                : currentPage * pageSize,
                            onChange: handlePaginationChange,
                            pageSize: pageSize,
                            defaultPageSize: 1,
                        }}
                        scroll={{ x: 800 }}
                        renderItem={(item) => (
                            <List.Item>
                                <Flex style={{width: "100%", position: "relative"}} gap={15}>
                                    <Flex style={{width: "30%"}}>
                                        {new Date(item.createdAt).toLocaleString()}
                                    </Flex>
                                    <Flex style={{width: "20%"}}>
                                        {item.subscriptionType === "DAYS_30" && <IntlMessage id={'subscription.30days'}/>}
                                        {item.subscriptionType === "DAYS_90" && <IntlMessage id={'subscription.90days'}/>}
                                        {item.subscriptionType === "DAYS_180" && <IntlMessage id={'subscription.180days'}/>}
                                    </Flex>
                                    <Flex style={{width: "50%"}}>
                                        {item.comment}
                                    </Flex>
                                </Flex>
                            </List.Item>
                        )}
                    />
                </Flex>

            </Flex>
            <Modal
                title={<IntlMessage id={'subscription.uploadAccount'}/>}
                open={openModal}
                // onOk={handleBuy}
                onCancel={() => setOpenModal(false)}
                // okText={<IntlMessage id={'upload'}/>}
                okButtonProps={{style: {display: "none"}}}
                cancelText={<IntlMessage id={'close'}/>}
            >
                <Flex align={"center"} justify={"center"} vertical gap={10}>
                    <Image preview={false} style={{width: "100%", maxWidth: 300}} src={BrainBrewKaspiQR}/>
                    <BlockLoading isLoading={buyLoading}/>
                    <Alert
                        message={<IntlMessage id={'subscription.uploadAccountTextAlert'}/>}
                        type={"info"}
                    />
                    <Upload fileList={fileList}  {...props}>
                        <SteveBloxButton icon={<UploadOutlined />}> <IntlMessage id={'upload'}/></SteveBloxButton>
                    </Upload>
                </Flex>

            </Modal>
        </Flex>
    );
};

export default SubscriptionPage;