import React from 'react';
import {useWindowSize} from "@uidotdev/usehooks";
import {useScroll} from "../../components/Scroll/Scroll";
import {useNavigate} from "react-router-dom";
import {Flex} from "antd";
import {clrs} from "../../constants/colors";
import Block from "../components/Block/Block";
import Header from "../components/Header/Header";
import {Fade} from "react-awesome-reveal";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import DefaultButton from "../../components/UI/Buttons/DefaultButton";
import contactsBanner from "../../images/contactsBanner.svg";
import contactsBack from "../../images/contactsBack.svg";
import MyText from "../../components/UI/Text/MyText";
import contacts1 from "../../images/contacts1.svg";
import contacts2 from "../../images/contacts2.svg";
import contacts3 from "../../images/contacts3.svg";
import contacts4 from "../../images/contacts4.svg";
import Footer from "../components/Footer/Footer";
import HorizontalDivider from "../../components/Divider/HorizontalDivider";

const ContactsPage = () => {
    const size = useWindowSize();
    const scroll = useScroll();
    const navigate = useNavigate();

    return (
        <Flex align={"center"} vertical gap={25} justify={"center"}
              style={{padding: "25px", width: "100%", backgroundColor: clrs.yellow}}>
            <Block vertical>
                <Header/>
                <Flex vertical gap={15} align={"flex-start"} justify={"center"} style={{
                    width: "100%",
                    padding: size.width > 1024 ? 100 : size.width > 425 ? 50 : 30,
                    position: "relative",
                    backgroundColor: clrs.blacker2,
                    minHeight: 500
                }}>

                    <Fade triggerOnce={true} cascade>
                        <p style={{zIndex: 10, color: clrs.white, fontSize: "2rem"}}>_______</p>
                        <p style={{zIndex: 10, color: clrs.white, fontSize: "1.2rem", fontWeight: 300}}><IntlMessage
                            id={'home.banner1'}/></p>
                        <p style={{zIndex: 10, color: clrs.white, fontSize: "2.2rem", fontWeight: 700}}><IntlMessage
                            id={'home.banner2'}/></p>
                        <p style={{zIndex: 10, color: clrs.yellow, fontSize: "1.2rem", fontWeight: 300}}><IntlMessage
                            id={'home.banner3'}/></p>
                        <DefaultButton onClick={() => {
                            navigate('/login')
                        }} style={{
                            boxShadow: "0px 0px 25px 2px rgba(0, 0, 0, 0.4)",
                            backgroundColor: clrs.white,
                            padding: size.width > 425 ? "10px 50px" : "10px 15px",
                            color: "black",
                            borderRadius: 40
                        }}><IntlMessage id={'home.bannerBtn'}/></DefaultButton>
                    </Fade>
                    {
                        size.width > 1024 &&
                        <img style={{position: "absolute", maxWidth: "500px", right: "7%", bottom: 0}}
                             src={contactsBanner} alt=""/>
                    }
                </Flex>
            </Block>

            <Block gap={45} justify={"center"} align={"center"}
                   style={{backgroundColor: clrs.white, padding: "25px 50px", position: "relative"}} vertical>
                <img src={contactsBack} style={{position: "absolute", width: "90%", top: 100, zIndex: 0}} alt=""/>
                <MyText size={"large"} style={{zIndex: 10}} strong>
                    <IntlMessage id={'contacts'}/>
                </MyText>
                <Flex gap={50} style={{width: "100%", maxWidth: 1000, zIndex: 0}} align={"center"} justify={"space-around"}>
                    {size.width > 768 && <img src={contacts1} style={{maxWidth: 250}} alt=""/>}
                    <MyText size={size.width > 768 ? "large" : "default"} style={{textAlign: "justify"}}>
                        <IntlMessage id={'contacts.text1'}/>
                    </MyText>
                </Flex>

                <Flex gap={50} style={{width: "100%", maxWidth: 1000, zIndex: 0}} align={"center"} justify={"space-around"}>
                    <MyText size={size.width > 768 ? "large" : "default"} style={{textAlign: "justify"}}>
                        <IntlMessage id={'contacts.text2'}/>
                    </MyText>
                    {size.width > 768 && <img src={contacts2} style={{maxWidth: 250}} alt=""/>}
                </Flex>

                <Flex gap={50} style={{width: "100%", maxWidth: 1000, zIndex: 0}} align={"center"} justify={"space-around"}>
                    {size.width > 768 && <img src={contacts3} style={{maxWidth: 250}} alt=""/>}
                    <MyText size={size.width > 768 ? "large" : "default"} style={{textAlign: "justify"}}>
                        <IntlMessage id={'contacts.text3'}/>
                    </MyText>
                </Flex>

                <Flex gap={50} style={{width: "100%", maxWidth: 1000, zIndex: 0}} align={"center"} justify={"space-around"}>
                    <MyText size={size.width > 768 ? "large" : "default"} style={{textAlign: "justify"}}>
                        <IntlMessage id={'contacts.text4'}/>
                    </MyText>
                    {size.width > 768 && <img src={contacts4} style={{maxWidth: 250}} alt=""/>}
                </Flex>

                <Flex vertical align={"flex-start"} justify={"flex-start"} gap={20}>
                    <MyText size={"large"} style={{zIndex: 10}}>
                        <strong><IntlMessage id={'contacts.email'}/>: </strong>
                        <span>info@brainbrew.kz</span>
                    </MyText>
                </Flex>
            </Block>

            <Footer/>
        </Flex>
    );
};

export default ContactsPage;