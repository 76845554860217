import React from 'react';
import {Link} from "react-router-dom";
import {HomeOutlined} from "@ant-design/icons";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {Breadcrumb, Flex, Form, Input, message, Radio, Select} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import ProfileSettings from "../Settings/pages/ProfileSettings";
import EmailSettings from "../Settings/pages/EmailSettings";
import {clrs} from "../../../../constants/colors";
import cl from "../../../global_styles.module.css";
import FormItem from "antd/lib/form/FormItem";
import SteveBloxButton from "../../../../components/UI/Buttons/SteveBloxButton";
import UserService from "../../../../services/UserService";
import {clearUser, setUserState} from "../../../../store/slices/userSlice";
import StatementService from "../../../../services/StatementService";
import {useDispatch} from "react-redux";

const StatementPage = () => {
    const breadItems = [
        {
            title: <Link to={'/p/home'}><HomeOutlined /></Link>,
        },
        {
            title: <IntlMessage id={'complaints-suggestions'}/>,
        },
    ]

    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const title = Form.useWatch('title', form);
    const body = Form.useWatch('body', form);
    const type = Form.useWatch('type', form);

    function handleOnSend() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                await StatementService.save(
                    title,
                    body,
                    type,
                )
                    .then(async (result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'sent'}/>, 5);
                        form.resetFields();
                    })
                    .catch((result) => {
                        message.destroy();

                        if (result.response.data === 'email is not confirmed') {
                            message.error(<IntlMessage id={'emailNotConfirmedError'}/>, 5);
                        } else {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        }
                        if (result.response.status === 401) {
                            message.destroy();
                            localStorage.removeItem('Authorization');
                            dispatch(clearUser())
                            message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                        }
                    })
            })
            .catch(() => {

            })
    }

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <MyText uppercase strong size={"large"}>
                <IntlMessage id={'complaints-suggestions'}/>
            </MyText>
            <Breadcrumb
                items={breadItems}
            />
            <Flex style={{width: "100%", padding: 15, backgroundColor: clrs.white, borderRadius: 8}}>
                <Form className={cl.form} form={form}>
                    <FormItem
                        rules={[
                            {
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                        ]}
                        label={<IntlMessage id={'statement.title'}/>}
                        labelCol={{span: 24}}
                        className={cl.formInputBlock} name="title"
                    >
                        <Input rootClassName={cl.formInput}/>
                    </FormItem>
                    <FormItem
                        rules={[
                            {
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                        ]}
                        label={<IntlMessage id={'statement.body'}/>}
                        labelCol={{span: 24}}
                        className={cl.formInputBlock} name="body"
                    >
                        <Input.TextArea rootClassName={cl.formInput} autoSize={{ minRows: 5 }}/>
                    </FormItem>
                    <FormItem
                        rules={[
                            {
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                        ]}
                        label={<IntlMessage id={'statement.type'}/>}
                        labelCol={{span: 24}}
                        className={cl.formInputBlock} name="type"
                    >
                        <Select
                            style={{maxWidth: 300, width: "100%"}}
                            options={[
                                {value: 'COMPLAINT', label: <IntlMessage id={'statement.type.complaint'}/>},
                                {value: 'SUGGESTION', label: <IntlMessage id={'statement.type.suggestion'}/>},
                            ]}
                        />
                    </FormItem>

                    <SteveBloxButton onClick={handleOnSend}>
                        <IntlMessage id={'send'}/>
                    </SteveBloxButton>
                </Form>
            </Flex>
        </Flex>
    );
};

export default StatementPage;