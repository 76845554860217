import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <g fill="currentColor">
                <path d="m3 0h1v3h-1z"/>
                <path d="m11 0h1v3h-1z"/>
                <path d="m6.6 14h-5.6v-8h13v.6c.4.2.7.4 1 .7v-6.3h-2v3h-3v-3h-5v3h-3v-3h-2v14h7.3c-.3-.3-.5-.6-.7-1z"/>
                <path d="m14 12h-3v-3h1v2h2z"/>
                <path
                    d="m11.5 8c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5 1.6-3.5 3.5-3.5zm0-1c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5z"/>
            </g>
        </svg>
    );
};

const SubscriptionIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default SubscriptionIcon;