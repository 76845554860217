import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const QuizService = {};
QuizService.getQuizAvailableSubjects = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/quiz/get/subjects/available`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        },
    });
}

QuizService.getQuizById = async (quizId) => {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/quiz/get`,
        params: {
            quizId: quizId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        },
    });
}

QuizService.getMyQuizList = async (
    page, limit
) => {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/quiz/get/my?page=${page - 1}&limit=${limit}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        },
    });

    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

QuizService.startQuiz = async (
    subjectCodes,
    quizLanguage
) => {
    let data = new FormData();
    data.append("subjectCodes", subjectCodes);
    data.append("quizLanguage", quizLanguage);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/quiz/start",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}


QuizService.finishQuiz = async (
    quizId,
) => {
    let data = new FormData();
    data.append("quizId", quizId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/quiz/finish",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default QuizService;