import React from 'react';
import classes from '../quiz.module.css';
import {Flex} from "antd";
import KazHistoryIcon from "../../../../../components/icons/subjectIcons/KazHistoryIcon";
import MatGramotnostIcon from "../../../../../components/icons/subjectIcons/MatGramotnostIcon";
import GramotnostChteIcon from "../../../../../components/icons/subjectIcons/GramotnostChteIcon";
import {LocalName} from "../../../../../utils/LocalName";
import InformatikaIcon from "../../../../../components/icons/subjectIcons/InformatikaIcon";
import MathIcon from "../../../../../components/icons/subjectIcons/MathIcon";
import PhysicsIcon from "../../../../../components/icons/subjectIcons/PhysicsIcon";
import ChemistryIcon from "../../../../../components/icons/subjectIcons/ChemistryIcon";
import BiologyIcon from "../../../../../components/icons/subjectIcons/BiologyIcon";
import GeographyIcon from "../../../../../components/icons/subjectIcons/GeographyIcon";

const SubjectItem = ({subject, ...props}) => {
    return (
        <Flex {...props} vertical gap={15} align={"center"} justify={"center"}>
            <SubjectIcon subject={subject}/>
            <p>{LocalName.getName(subject)}</p>
        </Flex>
    );
};

const SubjectIcon = ({subject}) => {
    if (subject?.nameRu === 'История Казахстана') {
        return <KazHistoryIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'Математическая грамотность') {
        return <MatGramotnostIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'Грамотность чтения') {
        return <GramotnostChteIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'Информатика') {
        return <InformatikaIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'Математика') {
        return <MathIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'Физика') {
        return <PhysicsIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'Химия') {
        return <ChemistryIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'Биология') {
        return <BiologyIcon style={{width: 35}}/>
    }
    if (subject?.nameRu === 'География') {
        return <GeographyIcon style={{width: 35}}/>
    }
    return null;
}

export default SubjectItem;