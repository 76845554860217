import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg height={35} viewBox="0 0 50.04 55.03">
            <g>
                <path className="cls-1" strokeWidth={0} fill={"currentColor"}
                      d="m46.72,20.83c-2.61-5.03-6.95-10.19-12.82-11.24-1.19-.21-2.39-.24-3.55-.1.86-.74,1.5-1.75,1.76-2.97.31-1.49-.14-6.22-2.24-6.51-.67-.09-.87.29-1.28.72-1.53,1.62-2.6,4.21-3.74,6.1-1.06,1.76-2.45,4.23-1.17,6.09-.23.26-.45.53-.66.82-.09-.74-.14-1.49-.11-2.27.1-2.37.96-4.58,1.07-6.92.02-.38-.51-.6-.77-.32-2.02,2.25-2.6,6.11-2.14,9.66C14.11,7.23,4.12,10.43,1.12,19.87c-3.17,9.94.56,25.95,9.29,32.2,2.02,1.44,4.39,2.48,6.85,2.85,3.26.49,5.81-.74,8.94-1.27,2.91-.5,5.85.52,8.84,0,2.61-.45,5.09-1.61,7.2-3.18,9.43-7.01,9.52-19.93,4.47-29.63ZM25.61,7.29c.9-1.46,1.76-3.58,2.96-4.79.79-.8,1.26-1.08,1.99-.21.65.78.73,2.37.65,3.3-.19,2.34-1.83,4.2-4.08,4.6.87-1.46,1.87-2.98,2.32-4.59.11-.4-.43-.74-.72-.42-1.3,1.42-2.12,3.36-3.03,5.03-.19.34-.02.66.23.82-.66.41-1.28.87-1.84,1.42-.85-1.69.68-3.8,1.51-5.16Zm16.77,39.16c-4.26,4.33-8.45,4.23-14.03,4.08-2.1-.06-3.69.57-5.72,1.04-3.47.81-6.49.58-9.61-1.23-5.16-2.99-7.66-8.87-9.08-14.39-1.45-5.65-2.64-12.49.17-17.93,3.67-7.13,11.53-7.97,17.03-2.46.08.08.18.12.27.15.24.99.57,1.92.97,2.75-.86-.03-1.7-.25-2.55-.54-.71-.24-1.05.77-.48,1.15,1.11.74,2.66,1.04,4.16.89.1.01.21,0,.3-.04,1.85-.26,3.57-1.21,4.23-2.89.17-.44-.4-.81-.77-.59-1.12.67-2.05,1.5-3.34,1.84-.13-.57-.26-1.15-.39-1.71.2-.08.38-.22.5-.47,3.21-6.17,10.76-5.11,15.22-1.13,3.98,3.56,6.42,9.12,7.43,14.27,1.2,6.09.17,12.64-4.31,17.2Z"/>
            </g>
        </svg>
    );
};

const BiologyIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default BiologyIcon;