import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";

const svg = () => {
    return (
        <svg height={35} viewBox="0 0 66.88 49.1">
            <g>
                <g>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m64.1,2.67c-.07-.21-.24-.39-.52-.4C43.49,1.09,23.08-.46,2.95.13c-.29,0-.36.32-.21.49C.46,1.31.01,4,0,6.41c-.03,6.6.35,13.24.52,19.84.08,3.05.17,6.1.26,9.16.08,2.63-.26,5.65.4,8.21,1.07,4.15,5.54,3.39,9.03,3.59,13.37.74,26.76,1.22,40.15,1.43,3.63.06,10.58,1.54,13.75-1.11,1.03-.86,1.35-1.99,1.55-3.27.49-3.16.27-6.6.37-9.8.26-7.64.49-15.28.67-22.93.05-2.31,1.15-8.69-2.59-8.86Zm1.09,18.35c-.19,5.8-.39,11.59-.62,17.39-.06,1.41.12,3.37-.19,4.74-.96,4.26-2.46,4.14-5.37,4.25-2.61.1-5.26-.05-7.88-.09-6.04-.09-12.08-.23-18.11-.43-5.77-.19-11.55-.43-17.32-.71-2.84-.14-5.84-.04-8.65-.47-2.57-.39-4.58-1.26-4.78-4.2-.08-1.27-.08-2.55-.12-3.82-.17-5.6-.35-11.19-.54-16.79C1.53,17.86-.77,1.52,3.99,1.18c.22-.02.34-.19.35-.36,19.6,1.45,39.37,1.56,59,2.57.05.24.23.44.54.43,3.14-.06,1.38,14.92,1.31,17.19Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m61.98,5.77c-19.16-.41-38.28-1.37-57.42-2.13-.29-.01-.4.26-.33.47-.03.03-.06.07-.07.13-1.67,12.3-.93,25.68.81,37.94.08.53.99.41.94-.13-.55-6.28-1.16-12.54-1.34-18.85-.18-6.28.21-12.55.13-18.83,18.76,1.45,37.64,2.03,56.45,2.46-.61,12.2-1.34,24.7-1.09,36.91-.07-.17-.23-.31-.47-.33-8.89-.44-17.82-.48-26.73-.69-8.88-.22-17.85-.77-26.73-.39-.42.02-.41.61,0,.65,8.85.82,17.84.7,26.73.92,8.9.22,17.82.62,26.73.61.25,0,.41-.14.48-.31h0c0,.36.51.34.55,0,1.47-12.23,1.62-25.06,1.54-37.38.52-.16.46-1.05-.18-1.06Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m20.68,14.98c-.14-.37-.51-.54-.88-.36-2.11,1.07-2.85,3.66-3.7,5.73-1.48,3.62-2.53,7.39-3.2,11.24-.09.52.69.76.81.22.34-1.51.71-3,1.14-4.48,2.65.03,5.31.08,7.96.11.22,1.33.4,2.67.54,4.02.08.79,1.34.8,1.25,0-.61-5.67-1.92-11.14-3.93-16.48Zm-5.63,11.71c.28-.93.57-1.85.9-2.76.82-2.28,1.76-6.23,3.86-7.88,1.24,3.39,2.18,6.83,2.83,10.35-2.52-.1-5.08,0-7.58.29Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m38.92,21.44c-.63-3.98-5.38-8.02-9.36-5.84-.19.1-.2.33-.11.49-.6,5.14-.55,10.75.04,15.88.01.09.05.16.1.21-.04.17,0,.37.21.52,1.67,1.21,6.72,1,6.97-1.71.14-1.5-1.19-2.26-2.74-2.61,3.1-.89,5.5-3.05,4.88-6.94Zm-5.67,7.71c.39.1.76.22,1.13.38.7.3,1.03.43,1.02,1.25-.01.81-.34.94-1.06,1.18-.47.16-.98.25-1.47.28-.95.05-1.78-.28-2.7-.41,0,0,0,0,0,0,.06-1,.12-2,.17-3.01,0,0,0,0,0,0,.2,0,.4,0,.59.02.06.02.13.03.21.03.04,0,.09,0,.13-.01.67.04,1.32.13,1.98.3Zm-2.11-1.49c-.32.03-.5.25-.55.5-.08,0-.15.02-.22.02.18-4.04.24-8.15.11-12.13,3.34-.74,6.83,2.27,7.25,5.59.54,4.28-3.01,5.68-6.58,6.02Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m52.3,15.72c-5.78-2.37-10.18,1.54-10.35,7.5-.13,4.92,4.15,11.26,9.6,8.01.34-.2.12-.81-.28-.66-9.72,3.73-11.68-18.08.72-13.73.72.25.99-.85.31-1.13Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m58.61,8.51c-1.52-.43-3.31-.48-4.88-.4-.41.02-.41.61,0,.64,1.6.11,3.19.35,4.78.45.4.02.46-.58.09-.68Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m58.79,10.03c-1.23-.12-2.48-.18-3.72-.21-.32,0-.4.52-.08.57,1.25.18,2.53.33,3.8.42.5.03.49-.73,0-.77Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m14.11,40.71c-2.03-.07-4.09.05-6.12.11-.25,0-.25.39,0,.39,2.03.06,4.09.18,6.12.11.4-.01.4-.6,0-.62Z"/>
                    <path fill={"currentColor"} strokeWidth={0}
                          d="m13.66,39.12c-1.38-.35-2.77-.37-4.16-.05-.34.08-.19.57.14.51,1.3-.23,2.57-.15,3.83.24.45.14.64-.58.19-.7Z"/>
                </g>
            </g>
        </svg>
    );
};

const EngIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default EngIcon;