import React, {useCallback, useEffect, useState} from 'react';
import {
    Alert,
    Avatar,
    Button,
    Checkbox, Col,
    Divider,
    Empty,
    Flex,
    Form,
    Input,
    List,
    message,
    Radio, Row,
    Select,
    Skeleton, Tag
} from "antd";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import MyText from "../../../../components/UI/Text/MyText";
import BhargaButton from "../../../../components/UI/Buttons/BhargaButton";
import QuizService from "../../../../services/QuizService";
import cl from "../../../global_styles.module.css";
import {LocalName} from "../../../../utils/LocalName";
import {UserOutlined} from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";
import {useDispatch} from "react-redux";
import {setQuizState} from "../../../../store/slices/quizSlice";
import {Link, useNavigate} from "react-router-dom";
import HorizontalDivider from "../../../../components/Divider/HorizontalDivider";
import {clearUser} from "../../../../store/slices/userSlice";
import DotsLoader from "../../../../components/LoadingComponents/Loaders/DotsLoader";
import SteveBloxButton from "../../../../components/UI/Buttons/SteveBloxButton";
import NorthButton from "../../../../components/UI/Buttons/NorthButton";
import logout from "../Logout/Logout";

const QuizzesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [initLoading, setInitLoading] = useState(true);
    // const [loading, setLoading] = useState(false);
    const [quizList, setQuizList] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);


    useEffect(() => {
        QuizService.getMyQuizList(currentPage, pageSize)
            .then((result) => {
                console.log(result)
                setQuizList(result.data);
                setHasMore(result.hasMore);
                setInitLoading(false);
            })
            .catch((result) => {
                console.log(result)
                setInitLoading(false);
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }, []);


    const handlePaginationChange = (page, pageSize) => {
        setInitLoading(true);
        setCurrentPage(page);
        setPageSize(pageSize);
        QuizService.getMyQuizList(page, pageSize)
            .then((result) => {
                setQuizList(result.data);
                setHasMore(result.hasMore);
                setInitLoading(false);
            })
            .catch((result) => {
                setInitLoading(false);
                message.error(<IntlMessage id={'error'}/>, 5);
                if (result.response.status === 401) {
                    message.destroy();
                    localStorage.removeItem('Authorization');
                    dispatch(clearUser())
                    message.warning(<IntlMessage id={'sessionProblems'}/>, 5);
                }
            })
    }

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <Flex style={{width: "100%"}}>
                <Alert type={"warning"} style={{width: "100%"}} message={<strong><IntlMessage id={'subscription.warning'}/></strong>} description={<IntlMessage id={'subscription.warning.text'}/>}/>
            </Flex>
            <Flex align={"center"} justify={"space-between"}
                  style={{padding: 20, width: "100%", minHeight: "150px", backgroundColor: "white", position: "relative", borderRadius: 8}} gap={20}>
                <MyText uppercase strong size={"large"}>
                    <IntlMessage id={'myQuizzes'}/>
                </MyText>
                <BhargaButton
                    onClick={() => {navigate('pass')}}
                    style={{padding: "15px 50px", fontSize: "1.2rem", borderRadius: "15px"}}
                ><IntlMessage id={'passQuiz'}/></BhargaButton>
            </Flex>
            <Flex align={"center"} justify={"flex-start"}
                  style={{padding: 20, width: "100%", minHeight: "150px", backgroundColor: "white", position: "relative", borderRadius: 8}} vertical gap={20}>
                <List
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<IntlMessage id={'nothingFound'}/>}/> }}
                    style={{width: "100%", justifyContent: "center"}}
                    loading={{indicator: <DotsLoader/>, spinning: initLoading}}
                    itemLayout="horizontal"
                    dataSource={quizList}
                    pagination={{
                        current: currentPage,
                        total: hasMore
                            ? currentPage * pageSize + 1
                            : currentPage * pageSize,
                        onChange: handlePaginationChange,
                        pageSize: pageSize,
                        defaultPageSize: 1,
                    }}
                    scroll={{ x: 800 }}
                    renderItem={(item) => (
                        <List.Item
                            actions={[<Link to={`/p/quiz/${item.id}`}><NorthButton>Открыть</NorthButton></Link>]}
                        >
                            <Flex style={{width: "100%"}} wrap={"wrap"} gap={20}>
                                <Flex style={{width: "100%", maxWidth: 230}} gap={20} vertical>
                                    <List.Item.Meta
                                        // avatar={<Avatar src={item.picture.large} />}
                                        title={<strong><IntlMessage id={'subjects'}/></strong>}
                                        description={
                                            <Flex gap={5} vertical>
                                                {item?.subjects.map((subject, i) => {
                                                    return (
                                                        <Flex key={new Date() + i + "subject"} >
                                                            <p>- {LocalName.getName(subject)}</p>
                                                        </Flex>
                                                    )
                                                })}

                                            </Flex>
                                        }
                                    />
                                </Flex>
                                <Flex gap={5}>
                                    <strong><IntlMessage id={'quiz.quizLanguage'}/></strong>
                                    <span>:</span>
                                    <span>
                                        {item?.quizLanguage === 'KZ' && <IntlMessage id={'passQuiz.chooseLan.KZ'}/>}
                                        {item?.quizLanguage === 'RU' && <IntlMessage id={'passQuiz.chooseLan.RU'}/>}
                                    </span>
                                </Flex>
                                <Flex gap={5}>
                                    <strong><IntlMessage id={'start'}/></strong>
                                    <span>:</span>
                                    <span>
                                        {new Date(item?.createdAt).toLocaleString()}
                                    </span>
                                </Flex>
                                <Flex gap={5}>
                                    <strong><IntlMessage id={'end'}/></strong>
                                    <span>:</span>
                                    <span>
                                        {new Date(item?.dueDate).toLocaleString()}
                                    </span>
                                </Flex>
                                <Flex gap={5}>
                                    <strong><IntlMessage id={'status'}/></strong>
                                    <span>:</span>
                                    <span>
                                        {!item?.isFinished ? <Tag color={'gold'}><IntlMessage id={'inProcess'}/></Tag> : <Tag color={'geekblue'}><IntlMessage id={'finished'}/></Tag>}
                                    </span>
                                </Flex>
                                <Flex gap={5}>
                                    <strong><IntlMessage id={'result'}/></strong>
                                    <span>:</span>
                                    <span>
                                        {item?.resultPoint !== null && `${item.resultPoint} / ${item.maxPoint}`}
                                    </span>
                                </Flex>
                            </Flex>

                        </List.Item>
                    )}
                />
            </Flex>
        </Flex>
    );
};



export default QuizzesPage;