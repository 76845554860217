import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {useWindowSize} from "@uidotdev/usehooks";
import classes from '../global_styles.module.css';
import PlatformHeader from "./components/Header/PlatformHeader";
import {Drawer, Flex} from "antd";
import PlatformNavigation from "./components/Navigation/PlatformNavigation";
import Logout from "./pages/Logout/Logout";
import NotFound from "./pages/404/NotFound";
import HomePage from "./pages/Home/HomePage";
import QuizzesPage from "./pages/Quizzes/QuizzesPage";
import QuizPage from "./pages/Quiz/QuizPage";
import './platform.module.css';
import SubscriptionPage from "./pages/Subscription/SubscriptionPage";
import PassTestPage from "./pages/PassTest/PassTestPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import SettingsPage from "./pages/Settings/SettingsPage";
import {setClosed} from "../../store/slices/platform/PlatformNavSlice";
import StatementPage from "./pages/Statement/StatementPage";

const Platform = () => {
    const isClosedNav = useSelector(state => state.platformNav.isClosed);
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const size = useWindowSize();

    useEffect(() => {
        console.log(isClosedNav)
        if (localStorage.getItem('isClosedNav') === null) {
            let windowWidth = window.innerWidth;
            if (windowWidth > 768) {
                dispatch(setClosed(false));
            } else {
                dispatch(setClosed(true));
            }
        }
    }, []);

    function setClosedDrawer() {
        dispatch(setClosed(true));
    }

    if (size.width <= 1024) {
        return (
            <div className={classes.main}>
                <PlatformHeader/>
                <div className={classes.inner_main}>
                    {/*<div className={[classes.nav, isClosedNav ? classes.closed : ""].join(" ")}>*/}
                    {/*    <div className={classes.nav_inner}>*/}
                    {/*        <PlatformNavigation/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Drawer
                        title={null}
                        placement={'left'}
                        closable={false}
                        onClose={setClosedDrawer}
                        open={!isClosedNav}
                        width={300}
                        drawerStyle={{padding: 0}}
                        bodyStyle={{padding: 0}}
                    >
                        <PlatformNavigation/>
                    </Drawer>
                    <div className={classes.body2}>
                        <Flex align={"flex-start"} justify={"flex-start"} style={{width: "100%", maxHeight: "100%", overflowX: "scroll", padding: size.width > 768 ? "15px 25px" : "15px"}}>
                            <Routes>
                                <Route path='/home' element={<HomePage/>}/>
                                <Route path='/profile' element={<ProfilePage/>}/>
                                <Route path='/quizzes' element={<QuizzesPage/>}/>
                                <Route path='/quizzes/pass' element={<PassTestPage/>}/>
                                <Route path='/quiz/:quizId/:subjectIndex?/question?/:questionIndex?' element={<QuizPage/>}/>
                                <Route path='/quiz/:quizId/:subjectIndex?/context?/:contextIndex?' element={<QuizPage/>}/>
                                <Route path='/subscription' element={<SubscriptionPage/>}/>
                                <Route path='/settings/:type?' element={<SettingsPage/>}/>
                                <Route path='/statement' element={<StatementPage/>}/>
                                <Route path='/logout' element={<Logout/>}/>
                                <Route path='/*' element={<NotFound/>}/>
                            </Routes>
                        </Flex>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.main}>
            <PlatformHeader/>
            <div className={classes.inner_main}>
                <div className={[classes.nav, isClosedNav ? classes.closed : ""].join(" ")}>
                    <div className={classes.nav_inner}>
                        <PlatformNavigation/>
                    </div>
                </div>
                <div className={[classes.body, isClosedNav ? classes.bodyMax : ""].join(" ")}>
                    <Flex align={"flex-start"} justify={"flex-start"} style={{width: "100%", maxHeight: "100%", overflowX: "scroll", padding: size.width > 768 ? "15px 25px" : "15px"}}>
                        <Routes>
                            <Route path='/home' element={<HomePage/>}/>
                            <Route path='/profile' element={<ProfilePage/>}/>
                            <Route path='/quizzes' element={<QuizzesPage/>}/>
                            <Route path='/quizzes/pass' element={<PassTestPage/>}/>
                            <Route path='/quiz/:quizId/:subjectIndex?/question?/:questionIndex?' element={<QuizPage/>}/>
                            <Route path='/quiz/:quizId/:subjectIndex?/context?/:contextIndex?' element={<QuizPage/>}/>
                            <Route path='/subscription' element={<SubscriptionPage/>}/>
                            <Route path='/settings/:type?' element={<SettingsPage/>}/>
                            <Route path='/statement' element={<StatementPage/>}/>
                            <Route path='/logout' element={<Logout/>}/>
                            <Route path='/*' element={<NotFound/>}/>
                        </Routes>
                    </Flex>

                </div>
            </div>
        </div>
    );
};

export default Platform;